import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import { loadFonts } from './plugins/webfontloader'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css';




loadFonts()

createApp(App)
  .use(router)
  .use(vuetify)
  .use(PrimeVue)
  .use(AutoComplete)
  .use(VueTelInput) 
  .mount('#app')
 

