<template>
  <div v-if="searchresult">

    <div class="container" style="margin-top:100px;">
      <v-card rounded="0" style="overflow: unset">
        <v-card rounded="0" style="padding: 10px 25px; overflow: unset">
          <v-card rounded="0" style="padding: 10px 25px; overflow: unset" v-if="roundModify">
            <div class="row d-flex">
              <div class="col-lg-5 col-12 d-flex justify-content-evenly">
                <div>
                  <h5 class="text-center">{{ tripData.from }}</h5>
                  <p class="text-center">{{ tripData.dedate }}</p>
                </div>
                <v-icon size="30">mdi-swap-horizontal</v-icon>
                <div>
                  <h5 class="text-center">{{ tripData.to }}</h5>
                  <p class="text-center">{{ tripData.redate }}</p>
                </div>
              </div>
              <div class="col-lg-2 col-6 align-center">
                <h6 class="text-center">Class Type</h6>
                <p class="text-center" style="font-weight: 500; color: gray">
                  {{ tripData.class }}
                </p>
              </div>
              <div class="col-lg-3 col-6 align-center">
                <h6 class="text-center">Passengers</h6>
                <div class="d-flex justify-center">
                  <span style="font-size: 14px; font-weight: 500; color: gray" v-if="tripData.aduls > 0">{{ tripData.aduls
                  }} Adult</span>
                  <span style="font-size: 14px; font-weight: 500; color: gray" v-if="tripData.aduls1 > 0">, {{
                    tripData.aduls1 }} Child</span>
                  <span style="font-size: 14px; font-weight: 500; color: gray" v-if="tripData.aduls2 > 0">, {{
                    tripData.aduls2 }} Lap Infant</span>
                </div>
              </div>
              <div class="col-lg-2 col-12 align-center" align="center">
                <v-btn color="#5191FA" @click="modifySection = !modifySection">Modify
                  <v-icon size="25" class="ps-2">
                    {{ modifySection ? "mdi-arrow-up-drop-circle-outline" : "mdi-pencil" }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-card>


          <v-card rounded="0" style="padding: 10px 25px; overflow: unset" v-if="onewayModify">
            <div class="row d-flex">
              <div class="col-lg-5 col-12">
                <div class="row">
                  <div class="col-12 d-flex justify-content-evenly">
                    <div>
                      <h5 class="text-center">{{ tripData.from }}</h5>
                    </div>
                    <v-icon size="30">mdi-arrow-right</v-icon>
                    <div>
                      <h5 class="text-center">{{ tripData.to }}</h5>
                    </div>
                  </div>

                  <div class="col-12 d-flex justify-content-evenly">
                    <p class="text-center m-0 mt-2">{{ tripData.dedate }} / Oneway</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-6 align-center">
                <h6 class="text-center">Class Type</h6>
                <p class="text-center" style="font-weight: 500; color: gray">
                  {{ tripData.class }}
                </p>
              </div>
              <div class="col-lg-3 col-6 align-center">
                <h6 class="text-center">Passengers</h6>
                <div class="d-flex justify-center">
                  <span style="font-size: 14px; font-weight: 500; color: gray" v-if="tripData.aduls > 0">{{ tripData.aduls
                  }} Adult</span>
                  <span style="font-size: 14px; font-weight: 500; color: gray" v-if="tripData.aduls1 > 0">, {{
                    tripData.aduls1 }} Child</span>
                  <span style="font-size: 14px; font-weight: 500; color: gray" v-if="tripData.aduls2 > 0">, {{
                    tripData.aduls2 }} Lap Infant</span>
                </div>
              </div>
              <div class="col-lg-2 col-12 align-center" align="center">
                <v-btn color="#5191FA" @click="modifySection = !modifySection">Modify
                  <v-icon size="25" class="ps-2">
                    {{ modifySection ? "mdi-arrow-up-drop-circle-outline" : "mdi-pencil" }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-card>

        <v-card rounded="0" style="padding: 10px 25px; overflow: unset" v-if="modifySection">
          <div class="row" align="center">
            <h6 style="color: #5191FA">FIND CHEAP TICKETS-SAVE BIG</h6>
          </div>
          <div class="row mt-1">
            <div class="col-md-4">
              <v-radio-group inline color="primary" v-model="red" style="position: relative; top: 0px">
                <v-radio label="Round Trip" value="1" class="normal-radio" :class="{ 'radio': red1 == 1 }"
                  @click="act(1)"></v-radio>
                <v-radio label="Oneway" value="2" class="normal-radio" :class="{ 'radio': red1 == 2 }"
                  @click="act(2)"></v-radio>
                <!-- <v-radio
            label="Multi-city"
            value="3"
            class="normal-radio"
            :class="{ radio: red1 == 3 }"
            @click="act(3)"
          ></v-radio> -->
              </v-radio-group>
            </div>

            <div class="col-md-5">
              <div class="row">
                <div class="col-1">
                  <span style="
                    font-size: 18px;
                    color: gray;
                    position: relative;
                    top: 5px;
                  ">
                    <v-icon> mdi-account-outline </v-icon>
                  </span>
                </div>
                <div class="col-10">
                  <v-select v-model="selectedCountry" :items="countries" ref="countrySelect" variant="underlined">
                    <!-- <template #item="{ item }"> -->
                    <template #item="{ }">
                      <div class="dropdown_section" style="cursor: pointer">

                        <div class="row d-flex align-center my-2 mx-1" style="height: 37px" @click.stop>
                          <div class="col-3" style="width: 38%">
                            <span style="
                              font-size: 14px;
                              font-weight: 500;
                              color: #4e4d4d;
                            ">Adult</span>
                          </div>
                          <div class="col-3 px-0" style="width: 9%">
                            <v-icon style="width: auto; color: grey; font-size: 18px">
                              mdi-human-male-female
                            </v-icon>
                          </div>
                          <div class="col-6 adult" style="width: 50%; height: auto">
                            <div class="row" style="padding: 0px">
                              <div class="col-4" @click="deg1()">
                                <div class="adult-plus">-</div>
                              </div>
                              <div class="col-4" style="background-color: white" align="center">
                                <div>{{ aduls }}</div>
                              </div>
                              <div class="col-4" @click="add1()">
                                <div class="adult-plus">+</div>
                              </div>
                            </div>
                          </div>
                          <!-- <div>{{ item.name }}</div> -->
                        </div>

                        <div class="row d-flex align-center my-2 mx-1" @click.stop>
                          <div class="col-4 d-flex flex-column pe-0" style="width: 38%">
                            <span style="
                              font-size: 14px;
                              font-weight: 500;
                              color: #4e4d4d;
                            ">Children</span>
                            <span style="font-size: 11px; color: #4e4d4d">2-11(yrs)</span>
                          </div>
                          <div class="col-2 px-0" style="width: 9%">
                            <v-icon style="width: auto; color: grey; font-size: 18px">
                              mdi-human-child
                            </v-icon>
                          </div>
                          <div class="col-6 adult" style="width: 50%">
                            <div class="row" style="padding: 0px">
                              <div class="col-4" @click="deg2()">
                                <div class="adult-plus">-</div>
                              </div>
                              <div class="col-4" style="background-color: white" align="center">
                                <div>{{ aduls1 }}</div>
                              </div>
                              <div class="col-4" @click="add2()">
                                <div class="adult-plus">+</div>
                              </div>
                            </div>
                          </div>
                          <!-- <div>{{ item.name }}</div> -->
                        </div>

                        <div class="row d-flex align-center my-2 mx-1" @click.stop>
                          <div class="col-5 d-flex flex-column pe-0" style="width: 38%">
                            <span style="
                              font-size: 14px;
                              font-weight: 500;
                              color: #4e4d4d;
                            ">Lap Infant</span>
                            <span style="font-size: 11px; color: #4e4d4d">2(yrs)</span>
                          </div>

                          <div class="col-1 ps-0" style="width: 9%">
                            <v-icon style="width: auto; color: grey; font-size: 18px">
                              mdi-baby-buggy
                            </v-icon>
                          </div>
                          <div class="col-6 adult" style="width: 50%">
                            <div class="row" style="padding: 0px">
                              <div class="col-4" @click="deg3()">
                                <div class="adult-plus">-</div>
                              </div>
                              <div class="col-4" style="background-color: white" align="center">
                                <div>{{ aduls2 }}</div>
                              </div>
                              <div class="col-4" @click="add3()">
                                <div class="adult-plus">+</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-flex align-center my-2 mx-1" @click.stop>
                          <div class="col-4" align="center">Cabin</div>

                          <div class="col-2" align="center">
                            <v-icon style="width: auto; font-size: 18px">
                              mdi-seat-passenger
                            </v-icon>
                          </div>
                          <div class="col-6 mt-3">
                            <v-select v-model="oneway.class" ref="mySelect"
                              :items="['Economy', 'Business', 'First Class']" variant="underlined"
                              style="color: gray"></v-select>
                          </div>
                        </div>

                        <div align="end" class="mt-4 pe-3" @click="(travels = false), localdone(), closeDropdown()">
                          <v-btn rounded="3" color="red" width="100px" height="30px">
                            Done
                          </v-btn>
                        </div>
                      </div>
                    </template>

                    <template #selection="{ }">
                      <div>
                        <span>{{ aduls }} Adults</span>
                        <span v-if="aduls1 > 0">, {{ aduls1 }} Children</span>
                        <span v-if="aduls2 > 0">, {{ aduls2 }} Infants</span>
                        <span>, {{ oneway.class }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <div v-if="rount">
            <div class="row my-4">
              <div class="col-md-3 d-flex my-3" style="height: 100% !important">
                <span>
                  <v-icon>mdi-airplane-takeoff</v-icon>
                </span>
                <span class="p-float-label">
                  <AutoComplete v-model="oneway.from" optionLabel="name" dropdown :suggestions="filteredCountries"
                    @change="onChangeFrom()" @click="fromClick(oneway)" @complete="search"
                    style="border-bottom: 1px solid lightgrey; width: 100%">
                    <template #option="slotProps">
                      <div class="flex align-items-center dropdown-box">
                        <div>{{ slotProps.option.originalName }}</div>
                      </div>
                    </template>
                  </AutoComplete>
                  <label for="ac">From</label>
                  <span class="p-error" v-if="fromErrorMessage">Departure is required</span>
                </span>
              </div>

              <div class="col-md-3 d-flex my-3" style="height: 100% !important">
                <span>
                  <v-icon>mdi-airplane-landing</v-icon>
                </span>
                <span class="p-float-label">
                  <AutoComplete v-model="oneway.to" optionLabel="name" dropdown :suggestions="filteredCountries"
                    @change="onChangeTo()" @click="toClick(oneway)" @complete="search"
                    style="border-bottom: 1px solid lightgrey; width: 100%">
                    <template #option="slotProps">
                      <div class="dropdown-container">
                        <div class="flex align-items-center dropdown-box">
                          <div>{{ slotProps.option.originalName }}</div>
                        </div>
                      </div>
                    </template>
                  </AutoComplete>
                  <label for="ac">To</label>
                  <span class="p-error" v-if="toErrorMessage">Arrival is required</span>
                </span>
              </div>

              <div class="col-md-2 d-flex my-3">
                <span class="icon-container">
                  <v-icon>mdi-calendar</v-icon>
                </span>
                <span class="p-float-label">
                  <label :class="clicked1 ? 'label-top' : 'datepicker-label'">
                    Departure
                  </label>
                  <VueDatePicker ref="dpRef1" v-model="oneway.dedate" :min-date="new Date()" :max-date="new Date(
                    new Date().getFullYear() + 1,
                    new Date().getMonth(),
                    new Date().getDate()
                  )
                    " :format="dateFormat" :hide-navigation="['time']" auto-apply @update:modelValue="fromDateChanged"
                    :on-click-outside="ClickOutDepat" class="px-0" />
                  <span class="p-error" v-if="fromDateError">Dep. Date is required</span>
                </span>
              </div>

              <div class="col-md-2 d-flex mt-3">
                <span class="icon-container">
                  <v-icon>mdi-calendar</v-icon>
                </span>
                <span class="p-float-label">
                  <label :class="clicked2 ? 'label-top' : 'datepicker-label2'">
                    Returning
                  </label>
                  <VueDatePicker ref="dpRef2" v-model="oneway.redate" :format="dateFormat" :min-date="oneway.dedate"
                    :max-date="new Date(
                      new Date().getFullYear() + 1,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                      " :hide-navigation="['time']" auto-apply @update:modelValue="toDateChanged"
                    :on-click-outside="ClickOutReturn" />
                  <span class="p-error" v-if="toDateError">Dep. Date is required</span>
                </span>
              </div>
              <div class="col-md-2">
                <div @click="sended()">
                  <v-btn color="#5191FA" height="40px" width="100" rounded="1">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <!-- <div class="row mt-4">
        



            <div class="col-4 mt-2 ms-3">
              <div @click="sended()">
                <v-btn color="#5191FA" height="50px" width="100" rounded="1"> <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>




          </div> -->

            <!-- <div class="row mt-4" align="center">
          <div @click="sended()">
            <v-btn color="red" height="40px" width="300" rounded="5"
              >Search
            </v-btn>
          </div>
        </div> -->
          </div>

          <div v-if="Oneway">
            <div class="row my-4">
              <div class="col-md-3 d-flex">
                <span>
                  <v-icon>mdi-airplane-takeoff</v-icon>
                </span>
                <span class="p-float-label">
                  <AutoComplete v-model="oneway.from" optionLabel="name" dropdown :suggestions="filteredCountries"
                    @complete="search" @click="fromClick(oneway)" style="border-bottom: 1px solid lightgrey; width: 100%">
                    <template #option="slotProps">
                      <div class="flex align-items-center dropdown-box">
                        <div>{{ slotProps.option.originalName }}</div>
                      </div>
                    </template>
                  </AutoComplete>
                  <label for="ac">From</label>
                  <span class="p-error" v-if="fromErrorMessage">Departure is required</span>
                </span>
              </div>

              <div class="col-md-3 d-flex">
                <span>
                  <v-icon>mdi-airplane-landing</v-icon>
                </span>
                <span class="p-float-label">
                  <AutoComplete v-model="oneway.to" optionLabel="name" dropdown :suggestions="filteredCountries"
                    @complete="search" @click="toClick(oneway)" style="border-bottom: 1px solid lightgrey; width: 100%">
                    <template #option="slotProps">
                      <div class="flex align-items-center dropdown-box">
                        <div>{{ slotProps.option.originalName }}</div>
                      </div>
                    </template>
                  </AutoComplete>
                  <label for="ac">To</label>
                  <span class="p-error" v-if="toErrorMessage">Arrival is required</span>
                </span>
              </div>

              <div class="col-md-3 d-flex">
                <span class="icon-container">
                  <v-icon>mdi-calendar</v-icon>
                </span>
                <span class="p-float-label">
                  <label :class="clicked1 ? 'label-top1' : 'datepicker-label'">
                    Departure
                  </label>
                  <VueDatePicker ref="dpRef3" v-model="oneway.dedate" :min-date="new Date()" :format="dateFormat"
                    :hide-navigation="['time']" auto-apply @update:modelValue="onewayDateChanged"
                    :on-click-outside="onewayClickOutside" class="px-0" style="height: 24px !important" />

                  <span class="p-error" v-if="fromDateError">Dep. Date is required</span>
                </span>
              </div>

              <div class="col-md-3">
                <div @click="sendedone()">
                  <v-btn color="#5191FA" height="40px" width="100" rounded="1">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- <div class="row my-4">
            <div class="col-md-3">
              <v-select v-model="select" :items="['Business', 'Economy', 'First Class']" variant="underlined"
                class="no-box-shadow" style="
                position: relative;
                top: -10px;
                width: 100%;
                padding-left: 34px;
              "></v-select>
            </div>

            <div class="col-md-3 d-flex">
              <v-select v-model="selectedCountry" :items="countries" ref="countrySelect" variant="underlined" style="
                position: relative;
                top: -10px;
                color: gray;
                padding-left: 34px;
              ">
               
                <template #item="{ }">
                  <div class="dropdown_section" style="width: 300px">
                    <div>
                      <p class="text-center">Travelers</p>
                      <hr />
                    </div>

                    <div class="row d-flex align-center bg-light my-2 mx-1" style="height: 37px" @click.stop>
                      <div class="col-3" style="width: 38%">
                        <span style="
                          font-size: 14px;
                          font-weight: 500;
                          color: #4e4d4d;
                        ">Adult</span>
                      </div>
                      <div class="col-3 px-0" style="width: 9%">
                        <v-icon style="width: auto; color: grey; font-size: 18px">
                          mdi-human-male-female
                        </v-icon>
                      </div>
                      <div class="col-6 adult" style="width: 50%; height: auto">
                        <div class="row" style="padding: 0px">
                          <div class="col-4" @click="deg1()">
                            <div class="adult-plus">-</div>
                          </div>
                          <div class="col-4" style="background-color: white" align="center">
                            <div>{{ aduls }}</div>
                          </div>
                          <div class="col-4" @click="add1()">
                            <div class="adult-plus">+</div>
                          </div>
                        </div>
                      </div>
                
                    </div>

                    <div class="row d-flex align-center bg-light my-2 mx-1" @click.stop>
                      <div class="col-4 d-flex flex-column pe-0" style="width: 38%">
                        <span style="
                          font-size: 14px;
                          font-weight: 500;
                          color: #4e4d4d;
                        ">Children</span>
                        <span style="font-size: 11px; color: #4e4d4d">2-11(yrs)</span>
                      </div>
                      <div class="col-2 px-0" style="width: 9%">
                        <v-icon style="width: auto; color: grey; font-size: 18px">
                          mdi-human-child
                        </v-icon>
                      </div>
                      <div class="col-6 adult" style="width: 50%">
                        <div class="row" style="padding: 0px">
                          <div class="col-4" @click="deg2()">
                            <div class="adult-plus">-</div>
                          </div>
                          <div class="col-4" style="background-color: white" align="center">
                            <div>{{ aduls1 }}</div>
                          </div>
                          <div class="col-4" @click="add2()">
                            <div class="adult-plus">+</div>
                          </div>
                        </div>
                      </div>
              
                    </div>

                    <div class="row d-flex align-center bg-light my-2 mx-1" @click.stop>
                      <div class="col-5 d-flex flex-column pe-0" style="width: 38%">
                        <span style="
                          font-size: 14px;
                          font-weight: 500;
                          color: #4e4d4d;
                        ">Lap Infant</span>
                        <span style="font-size: 11px; color: #4e4d4d">2(yrs)</span>
                      </div>

                      <div class="col-1 ps-0" style="width: 9%">
                        <v-icon style="width: auto; color: grey; font-size: 18px">
                          mdi-baby-buggy
                        </v-icon>
                      </div>
                      <div class="col-6 adult" style="width: 50%">
                        <div class="row" style="padding: 0px">
                          <div class="col-4" @click="deg3()">
                            <div class="adult-plus">-</div>
                          </div>
                          <div class="col-4" style="background-color: white" align="center">
                            <div>{{ aduls2 }}</div>
                          </div>
                          <div class="col-4" @click="add3()">
                            <div class="adult-plus">+</div>
                          </div>
                        </div>
                      </div>
              
                    </div>

                    <div align="end" class="mt-4 pe-3" @click="(travels = false), localdone(), closeDropdown()">
                      <v-btn rounded="3" color="red" width="100px" height="30px">
                        Done
                      </v-btn>
                    </div>
                  </div>
                </template>

                <template #selection="{ }">
                  <div>
                    <span>{{ aduls }} Ad</span>
                    <span v-if="aduls1 > 0">, {{ aduls1 }} Chd</span>
                    <span v-if="aduls2 > 0">, {{ aduls2 }} Inf</span>
                  </div>
                </template>
              </v-select>
            </div>


            <div class="col-4 mt-2 ms-3">
              <div @click="sendedone()">
                <v-btn color="#5191FA" height="50px" width="100" rounded="1"> <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>

          </div> -->
          </div>

          <div v-if="multicity">
            <div v-for="(data, index) of input" :key="index">
              <!-- <div class="row my-4"> -->
              <div class="row my-4">
                <div class="col-md-3 d-flex">
                  <span class="icon-container">
                    <v-icon>mdi-airplane-takeoff</v-icon>
                  </span>
                  <span class="p-float-label">
                    <AutoComplete v-model="data.from" optionLabel="name" dropdown :suggestions="filteredCountries"
                      @complete="search" @change="onChangeMultiFrom(index)" @click="multiFromClick(index)"
                      style="border-bottom: 1px solid lightgrey; width: 100%">
                      <template #option="slotProps">
                        <div class="flex align-items-center dropdown-box" @click="focus5(index)">
                          <div>{{ slotProps.option.originalName }}</div>
                        </div>
                      </template>
                    </AutoComplete>
                    <label for="ac">From</label>
                    <span class="p-error" v-if="data.multiFromError">Departure is required</span>
                  </span>
                </div>

                <div class="col-md-3 d-flex">
                  <span class="icon-container">
                    <v-icon>mdi-airplane-landing</v-icon>
                  </span>

                  <span class="p-float-label">
                    <AutoComplete v-model="data.to" ref="multiTo" optionLabel="name" dropdown
                      :suggestions="filteredCountries" @complete="search" @change="onChangeMultiTo(index)"
                      @click="multiToClick(index)" style="border-bottom: 1px solid lightgrey; width: 100%">
                      <template #option="slotProps">
                        <div class="flex align-items-center dropdown-box" @click="focus6(index)">
                          <div>{{ slotProps.option.originalName }}</div>
                        </div>
                      </template>
                    </AutoComplete>
                    <label for="ac">To</label>
                    <span v-if="data.multiToError" class="p-error">Arrival is required</span>
                  </span>
                </div>

                <div class="col-md-4 d-flex">
                  <span class="icon-container">
                    <v-icon>mdi-calendar</v-icon>
                  </span>

                  <span class="p-float-label">
                    <label :class="data.clicked3 ? 'multiLabel-top' : 'multiDatepicker-label'
                      ">
                      Departure
                    </label>
                    <VueDatePicker v-model="data.departDate" ref="dpRef4" :min-date="new Date()" :format="dateFormat"
                      :hide-navigation="['time']" auto-apply @update:modelValue="(newValue) => multiDateChanged(index, newValue)
                        " :on-click-outside="multiClickOutside()" />
                    <span v-if="data.multiDateError" class="p-error">Dep. Date is required</span>
                  </span>
                </div>

                <div class="col-md-2" @click="delet()">
                  <v-icon v-if="input.length > 1" color="red">
                    mdi-close-circle
                  </v-icon>
                </div>
              </div>
            </div>

            <div class="col-md-10" align="end" @click="send(index)">
              <v-icon color="green">mdi-plus-circle</v-icon><span>Add More</span>
            </div>

            <div class="row my-4">
              <div class="col-md-3">
                <v-select v-model="select" :items="['Business', 'Economy', 'First Class']" variant="underlined"
                  class="no-box-shadow" style="
                  position: relative;
                  top: -10px;
                  width: 100%;
                  padding-left: 34px;
                "></v-select>
              </div>

              <div class="col-md-3">
                <v-select v-model="selectedCountry" :items="countries" ref="countrySelect" variant="underlined" style="
                  position: relative;
                  top: -10px;
                  color: gray;
                  padding-left: 34px;
                ">
                  <!-- <template #item="{ item }"> -->
                  <template #item="{ }">
                    <div class="dropdown_section" style="width: 300px">
                      <!-- <div>
                      <p class="text-center">Travelers</p>
                      <hr />
                    </div> -->

                      <div class="row d-flex align-center bg-light my-2 mx-1" style="height: 37px" @click.stop>
                        <div class="col-3" style="width: 38%">
                          <span style="
                            font-size: 14px;
                            font-weight: 500;
                            color: #4e4d4d;
                          ">Adult</span>
                        </div>
                        <div class="col-3 px-0" style="width: 9%">
                          <v-icon style="width: auto; color: grey; font-size: 18px">
                            mdi-human-male-female
                          </v-icon>
                        </div>
                        <div class="col-6 adult" style="width: 50%; height: auto">
                          <div class="row" style="padding: 0px">
                            <div class="col-4" @click="deg1()">
                              <div class="adult-plus">-</div>
                            </div>
                            <div class="col-4" style="background-color: white" align="center">
                              <div>{{ aduls }}</div>
                            </div>
                            <div class="col-4" @click="add1()">
                              <div class="adult-plus">+</div>
                            </div>
                          </div>
                        </div>
                        <!-- <div>{{ item.name }}</div> -->
                      </div>

                      <div class="row d-flex align-center bg-light my-2 mx-1" @click.stop>
                        <div class="col-4 d-flex flex-column pe-0" style="width: 38%">
                          <span style="
                            font-size: 14px;
                            font-weight: 500;
                            color: #4e4d4d;
                          ">Children</span>
                          <span style="font-size: 11px; color: #4e4d4d">2-11(yrs)</span>
                        </div>
                        <div class="col-2 px-0" style="width: 9%">
                          <v-icon style="width: auto; color: grey; font-size: 18px">
                            mdi-human-child
                          </v-icon>
                        </div>
                        <div class="col-6 adult" style="width: 50%">
                          <div class="row" style="padding: 0px">
                            <div class="col-4" @click="deg2()">
                              <div class="adult-plus">-</div>
                            </div>
                            <div class="col-4" style="background-color: white" align="center">
                              <div>{{ aduls1 }}</div>
                            </div>
                            <div class="col-4" @click="add2()">
                              <div class="adult-plus">+</div>
                            </div>
                          </div>
                        </div>
                        <!-- <div>{{ item.name }}</div> -->
                      </div>

                      <div class="row d-flex align-center bg-light my-2 mx-1" @click.stop>
                        <div class="col-5 d-flex flex-column pe-0" style="width: 38%">
                          <span style="
                            font-size: 14px;
                            font-weight: 500;
                            color: #4e4d4d;
                          ">Lap Infant</span>
                          <span style="font-size: 11px; color: #4e4d4d">2(yrs)</span>
                        </div>

                        <div class="col-1 ps-0" style="width: 9%">
                          <v-icon style="width: auto; color: grey; font-size: 18px">
                            mdi-baby-buggy
                          </v-icon>
                        </div>
                        <div class="col-6 adult" style="width: 50%">
                          <div class="row" style="padding: 0px">
                            <div class="col-4" @click="deg3()">
                              <div class="adult-plus">-</div>
                            </div>
                            <div class="col-4" style="background-color: white" align="center">
                              <div>{{ aduls2 }}</div>
                            </div>
                            <div class="col-4" @click="add3()">
                              <div class="adult-plus">+</div>
                            </div>
                          </div>
                        </div>
                        <!-- <div>{{ item.name }}</div> -->
                      </div>

                      <div align="end" class="mt-4 pe-3" @click="(travels = false), localdone(), closeDropdown()">
                        <v-btn rounded="3" color="red" width="100px" height="30px">
                          Done
                        </v-btn>
                      </div>
                    </div>
                  </template>

                  <template #selection="{ }">
                    <div>
                      <span>{{ aduls }} Ad</span>
                      <span v-if="aduls1 > 0">, {{ aduls1 }} Chd</span>
                      <span v-if="aduls2 > 0">, {{ aduls2 }} Inf</span>
                    </div>
                  </template>
                </v-select>
              </div>

              <div class="col-4 mt-2 ms-3">
                <div @click="sended()">
                  <v-btn color="#5191FA" height="40px" width="100" rounded="1">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <!-- <div class="row" align="center">
          <div>
            <v-btn
              color="red"
              height="40px"
              width="300"
              rounded="5"
              @click="sendedMulticity()"
              >Search
            </v-btn>
          </div>
        </div> -->
          </div>
        </v-card>
      </v-card>
    </div>


    <!-- <div class="container mt-5">
    <v-card rounded="0" style="position: inherit">
      <div class="air-title">
        <h6 style="margin-left: 15px">Pick your preferred Airline</h6>
      </div>
      <div class="row mt-3 ms-2">
        <div class="col-md-4">
          <p>Compare and Choose preffered Airlines</p>
          <div>
            <v-btn
              variant="outlined"
              color="info"
              width="150px"
              style="text-transform: capitalize"
            >
              Non Stop
            </v-btn>
          </div>
          <br />
          <div>
            <v-btn
              variant="outlined"
              color="info"
              width="150px"
              style="text-transform: capitalize"
            >
              1+ Stops
            </v-btn>
          </div>
        </div>
        <div class="col-md-4 mt-4">
          <v-card class="cart1">

            <h6 class="mt-2 ms-2">Airline</h6>
            <hr>
            <p>USD 100</p>
            <hr />
            <p>USD 100</p>
          </v-card>
        </div>
      </div>
      <p class="ms-3 mt-2" style="font-size: 13px; font-weight: 500">
        Fares incl. taxes & fees. Airfares include an applied Booking Bonus.
        Additional baggage fees may apply. Flights displayed may be for
        alternate dates and/or airports. Certain results may be outside your
        search criteria.
      </p>
      <br />
    </v-card>
  </div> -->

    <div class="container">
      <div class="row mt-4">
        <div class="col-lg-3">
          <v-card rounded="0">
            <div style="background-color:#0c4ca3; padding: 7px 3px">
              <div class="row" style="color: white">
                <div class="col-6">
                  <div>
                    <v-icon> mdi-filter </v-icon><span>|</span>
                    <span style="font-size: 17px; font-weight: 500">
                      Filter By</span>
                  </div>
                </div>
                <div class="col-4" align="center">
                  <!-- <div>Reset All</div> -->
                </div>
                <div class="col-2 filter-toggle" align="center">
                  <v-icon size="40" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                    aria-expanded="true" aria-controls="collapse-Example" v-model="isIcon" @click="isIcon = !isIcon"
                    style="overflow-anchor: none">{{
                      isIcon
                      ? "mdi-chevron-up-circle-outline"
                      : "mdi-chevron-down-circle-outline"
                    }}</v-icon>
                </div>
              </div>
            </div>

            <div style="padding: 10px" class="collapse" :class="{ show: isIcon }" aria-expanded="false"
              id="collapse-Example">
              <div>
                <!-- <div class="stop mb-3">Stops</div> -->
                <br />
                <div class="row gy-0">
                  <div class="col-8">
                    <v-checkbox label="Non Stop" v-model="NonStopFil" color="primary"
                      @click="filterNonStop()"></v-checkbox>
                  </div>
                  <!-- <div class="col-4">
                  <div>USD 100</div>
                </div> -->
                </div>

                <div class="row">
                  <div class="col-8">
                    <v-checkbox label="1 Stop" v-model="OneStopFil" color="primary" @click="filterOneStop()"></v-checkbox>
                  </div>
                  <!-- <div class="col-4">
                  <div>USD 100</div>
                </div> -->
                </div>

                <div class="row">
                  <div class="col-8">
                    <v-checkbox label="1+ Stop" v-model="OnePlusFil" color="primary"
                      @click="filterOnePlusStop()"></v-checkbox>
                  </div>
                  <!-- <div class="col-4">
                  <div>USD 100</div>
                </div> -->
                </div>

                <hr />
                <div class="stop mb-3">Baggage</div>
                <div class="row mt-1">
                  <div class="col-8">
                    <v-checkbox label="Baggage Inclusive" color="primary"></v-checkbox>
                  </div>
                  <!-- <div class="col-4" align="center">
                  <div>(100)</div>
                </div> -->
                </div>

                <hr />
                <div class="stop mb-3">Fare Type</div>
                <div class="row mt-1">
                  <div class="col-8">
                    <v-checkbox label="Refundable" color="primary"></v-checkbox>
                  </div>
                  <!-- <div class="col-4" align="center">
                  <div>(94)</div>
                </div> -->
                </div>

                <div class="row">
                  <div class="col-8">
                    <v-checkbox label="Non Refundable" color="primary"></v-checkbox>
                  </div>
                  <!-- <div class="col-4" align="center">
                  <div>(6)</div>
                </div> -->
                </div>

                <hr />
                <div class="stop">
                  Price Range
                  <span style="font-size: 13px"> (Avg.per pax)</span>
                </div>
                <div class="row me-1">
                  <v-range-slider v-model="changevalue" :min="minimum" :max="maximum" :step="steps" strict
                    color="#0c4ca3"></v-range-slider>
                </div>
                <div class="row">
                  <div class="col-6" align="center">
                    <p>USD {{ changevalue[0] }}</p>
                  </div>
                  <div class="col-6" align="center">
                    <p>USD {{ changevalue[1] }}</p>
                  </div>
                </div>

                <br />

              </div>

            </div>
          </v-card>
        </div>
        <div class="col-lg-9">
          <div v-if="roundtrip">
            <round></round>
          </div>
          <div v-if="multitrip">
            <multicity></multicity>
          </div>
          <div>


            <div class="mt-3" v-for="(res, index) of rountresult" :key="index">
              <div class="hoveredCard">
                <v-card rounded="0" style="padding: 20px 0px">


                  <div class="row m-1">
                    <div class="col-lg-9">
                      <div>
                        <div class="mt-2">
                          <div>
                            <v-card style="overflow: unset; padding: 1px">
                              <div class="row mt-4 g-0 mb-2">
                                <div class="col-md-3 col-12 mb-3 mb-md-0">
                                  <div class="row ms-1">
                                    <div class="col-4 airline-logo">
                                      <v-img :src="getlogo(res.Depature)" width="30px"></v-img>
                                    </div>
                                    <div class="col-8 ps-0 ps-md-2">
                                      <p class="res-name">
                                        {{ getname(res.Depature) }}

                                        <br />
                                        {{ getid(res.Depature) }} -
                                        {{ getNumber(res.Depature) }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-2 col-2" align="center">
                                  <p class="res-time">
                                    <span style="font-weight: 400">{{
                                      getDate(res.Depature)
                                    }}</span>
                                    <br />
                                    <span style="font-weight: 400; font-size: 18px">{{ getTime(res.Depature) }}
                                    </span>
                                  </p>
                                </div>

                                <div class="col-md-1 col-2 d-flex align-center justify-center">
                                  <p class="res-time" style="margin-bottom: 4px; font-size: 18px">
                                    {{ getAirportCode(res.Depature) }}
                                  </p>
                                </div>



                                <div class="col-md-3 col-4 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 0">
                                  <p class="res-stop m-0 text-center">Direct</p>
                                  <div class="d-flex align-center justify-center">
                                    <hr style="width:87%; border:1px solid black;">
                                    <v-icon color="grey"
                                      style="font-size:22px; height:15px; position:relative; right:3px; transform: rotate(90deg) !important;">
                                      mdi-airplane</v-icon>
                                  </div>

                                  <div class="d-flex justify-center time-section">
                                    <v-icon color="grey">mdi-clock-outline </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>




                                <div class="col-md-3 col-4 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 1">
                                  <p class="res-stop m-0 text-center">{{ res.Depaturejourney.Stops }} Stops</p>


                                  <div class="d-flex align-center justify-center" style="position: relative;">
                                    <hr style="width:45%; border:1px solid black;">

                                    <v-icon size="10" @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)">mdi-circle</v-icon>

                                    <div v-if="res.Depature.length >= 2" class="hoverData1" id="tooltip"
                                      :class="{ visible: res.hoveredName1 }">
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getarrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr style="width:32%; border:1px solid black;">

                                    <v-icon color="grey" size="22"
                                      style="position:relative; right:3px; transform: rotate(90deg) !important;">
                                      mdi-airplane</v-icon>
                                  </div>

                                  <div class="d-flex justify-center time-section">
                                    <v-icon color="grey">mdi-clock-outline </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>




                                <div class="col-md-3 col-4 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops >= 2">
                                  <p class="res-stop m-0 text-center">{{ res.Depaturejourney.Stops }} Stops</p>


                                  <div class="d-flex align-center justify-center" style="position: relative;">
                                    <hr style="width:40%; border:1px solid black;">

                                    <v-icon size="10" @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)">mdi-circle</v-icon>

                                    <div v-if="res.Depature.length >= 2" class="hoverDataStop1" id="tooltip"
                                      :class="{ visible: res.hoveredName1 }">
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getarrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr style="width:15%; border:1px solid black;">

                                    <v-icon size="10" @mouseover="showDetails2(res, index)"
                                      @mouseout="hideDetails2(res)">mdi-circle</v-icon>
                                    <div v-if="res.Depature.length >= 3" class="hoverDataStop2" id="tooltip"
                                      :class="{ visible: res.hoveredName2 }">
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          get1arrtime(res.Depature, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          get2arrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr style="width:25%; border:1px solid black;">
                                    <v-icon size="22" color="grey"
                                      style="position:relative; right:3px; transform: rotate(90deg) !important;">
                                      mdi-airplane</v-icon>
                                  </div>

                                  <div class="d-flex justify-center time-section">
                                    <v-icon color="grey">mdi-clock-outline </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>





                                <div class="col-md-1 col-2 d-flex align-center justify-center">
                                  <p class="res-time" style="margin-bottom: 4px; font-size: 18px">
                                    {{ getArrivalCode(res.Depature) }}
                                  </p>
                                </div>

                                <div class="col-md-2 col-2" align="center">
                                  <p class="res-time">
                                    <span style="font-weight: 400">
                                      {{ getArrivaldate(res.Depature) }}</span>
                                    <br />
                                    <span style="font-weight: 400; font-size: 18px">{{ getArrivalTime(res.Depature) }}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </v-card>
                          </div>
















                          <br />












                          <div>
                            <v-card style="overflow: unset; padding: 1px">
                              <div class="row mt-4 g-0 mb-2">
                                <div class="col-md-3 col-12 mb-3 mb-md-0">
                                  <div class="row ms-1">
                                    <div class="col-sm-4 col-2 airline-logo">
                                      <v-img :src="getlogo1(res.Return)" width="30px"></v-img>
                                    </div>
                                    <div class="col-sm-8 col-10 ps-0 ps-md-2">
                                      <p class="res-name">
                                        {{ getreturnname(res.Return) }}

                                        <br />
                                        {{ getreturnid(res.Return) }} -
                                        {{ getreturnNumber(res.Return) }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-2 col-2" align="center">
                                  <p class="res-time">
                                    <span style="font-weight: 400">{{
                                      getreturnDate(res.Return)
                                    }}</span>
                                    <br />
                                    <span style="font-weight: 400; font-size: 18px">{{ getreturnTime(res.Return) }}
                                    </span>
                                  </p>
                                </div>

                                <div class="col-md-1 col-2 d-flex align-center justify-center">
                                  <p class="res-time" style="margin-bottom: 4px; font-size: 18px">
                                    {{ getreturnAirportCode(res.Return) }}
                                  </p>
                                </div>







                                <div class="col-md-3 col-4 d-flex flex-column justify-center"
                                  v-if="res.Returnjourney.Stops == 0">
                                  <p class="res-stop m-0 text-center">Direct</p>
                                  <div class="d-flex align-center justify-center">
                                    <hr style="width:87%; border:1px solid black;">
                                    <v-icon color="grey"
                                      style="font-size:22px; height:15px; position:relative; right:3px; transform: rotate(90deg) !important;">
                                      mdi-airplane</v-icon>
                                  </div>

                                  <div class="d-flex justify-center time-section">
                                    <v-icon color="grey">mdi-clock-outline </v-icon>
                                    <span>{{ res.Returnjourney.Time }}</span>
                                  </div>
                                </div>




                                <div class="col-md-3 col-4 d-flex flex-column justify-center"
                                  v-if="res.Returnjourney.Stops == 1">
                                  <p class="res-stop m-0 text-center">{{ res.Returnjourney.Stops }} Stops</p>


                                  <div class="d-flex align-center justify-center" style="position: relative;">
                                    <hr style="width:45%; border:1px solid black;">

                                    <v-icon size="10" @mouseover="showDetails3(res, index)"
                                      @mouseout="hideDetails3(res)">mdi-circle</v-icon>

                                    <div v-if="res.Depature.length >= 2" class="hoverData1" id="tooltip"
                                      :class="{ visible: res.hoveredName3 }">
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getreturndata(res.Return)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getreturntime(res.Return)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr style="width:32%; border:1px solid black;">

                                    <v-icon color="grey" size="22"
                                      style="position:relative; right:3px; transform: rotate(90deg) !important;">
                                      mdi-airplane</v-icon>
                                  </div>

                                  <div class="d-flex justify-center time-section">
                                    <v-icon color="grey">mdi-clock-outline </v-icon>
                                    <span>{{ res.Returnjourney.Time }}</span>
                                  </div>
                                </div>




                                <div class="col-md-3 col-4 d-flex flex-column justify-center"
                                  v-if="res.Returnjourney.Stops >= 2">
                                  <p class="res-stop m-0 text-center">{{ res.Returnjourney.Stops }} Stops</p>


                                  <div class="d-flex align-center justify-center" style="position: relative;">
                                    <hr style="width:40%; border:1px solid black;">

                                    <v-icon size="10" @mouseover="showDetails3(res, index)"
                                      @mouseout="hideDetails3(res)">mdi-circle</v-icon>

                                    <div v-if="res.Depature.length >= 2" class="hoverDataStop1" id="tooltip"
                                      :class="{ visible: res.hoveredName3 }">
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getreturndata(res.Return)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getreturntime(res.Return)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr style="width:15%; border:1px solid black;">

                                    <v-icon size="10" @mouseover="showDetails4(res, index)"
                                      @mouseout="hideDetails4(res)">mdi-circle</v-icon>
                                    <div v-if="res.Depature.length >= 3" class="hoverDataStop2" id="tooltip"
                                      :class="{ visible: res.hoveredName4 }">
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          get1returntime(res.Return, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          get2returntime(res.Return)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr style="width:25%; border:1px solid black;">
                                    <v-icon size="22" color="grey"
                                      style="position:relative; right:3px; transform: rotate(90deg) !important;">
                                      mdi-airplane</v-icon>
                                  </div>

                                  <div class="d-flex justify-center time-section">
                                    <v-icon color="grey">mdi-clock-outline </v-icon>
                                    <span>{{ res.Returnjourney.Time }}</span>
                                  </div>
                                </div>



                                <div class="col-md-1 col-2 d-flex align-center justify-center">
                                  <p class="res-time" style="margin-bottom: 4px; font-size: 18px">
                                    {{ getreturnArrivalcode(res.Return) }}
                                  </p>
                                </div>

                                <div class="col-md-2 col-2" align="center">
                                  <p class="res-time">
                                    <span style="font-weight: 400">{{
                                      getreturnArrivaldate(res.Return)
                                    }}</span>
                                    <br />
                                    <span style="font-weight: 400; font-size: 18px">
                                      {{ getreturnArrivalTime(res.Return) }}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </v-card>
                          </div>
                        </div>






                        <!-- Seat................ -->

                        <div class="row d-flex mt-3">

                          <div class="col-md-3" align="center">
                            <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                            <span style="font-size: 12px; color: gray">{{
                              getdepseat(res.Depature) >= getretseat(res.Return)
                              ? getdepseat(res.Return)
                              : getretseat(res.Depature)
                            }}
                              Seats</span>
                          </div>


                          <div class="col-md-3 d-flex align-center" align="center">
                            <span><v-icon size="20">mdi-cash</v-icon></span>
                            <div>
                              <span style="
                                font-size: 11px;
                                color: gray;
                                padding-left: 5px;
                              ">{{ res.Refund }}</span>
                            </div>
                          </div>



                          <div class="col-md-3" align="center">
                            <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                            <span style="font-size: 12px; color: gray">{{
                              this.oneway.class
                            }}</span>
                          </div>


                          <div class="col-md-3 d-flex align-center" @click="flighted1(res, index)" align="center">
                            <span style="
                              color: #36c;
                              font-size: 12px;
                              margin-top: 2px;
                              cursor: pointer;
                            ">
                              Flight Details<span style="position: relative; right: 3px; top: 1px">
                                <span><v-icon>mdi-chevron-down</v-icon></span>
                              </span>
                            </span>
                          </div>

                        </div>


                        <!-- Seat End................ -->








                      </div>
                    </div>




                    <div class="col-lg-3 d-flex align-center justify-center">

                      <div class="d-flex flex-column align-center">
                        <h2 style="color: navy"><span style="font-size:26px;">USD</span> {{ getprice(res) }}</h2>
                        <!-- <p style="font-size: 14px">
                          Per person
                        </p> -->
                        <v-btn color="#5191fa" width="180px" height="50px" style="color: white"
                          @click.prevent="selected(res, index)">Select</v-btn>
                      </div>

                    </div>




                    <div class="col-md-9 col-12">
                      <div>
                        <div v-if="res.Flightdepature">
                          <v-card class="mt-4">
                            <!-- <div style="margin-top: 8px">
                                <hr />
                              </div> -->
                            <div class="pa-2">
                              <div style="
                                font-size: 15px;
                                font-weight: 500;
                                background-color: #e8f0fd;
                                padding: 5px;
                              ">
                                <span>DEPARTURE</span>
                                <span style="margin-left: 10px">
                                  Total Trip Duration :
                                  {{ res.Depaturejourney.Time }}</span>
                              </div>
                            </div>

                            <v-container style="max-width: 100%; padding: 0px 15px 15px 5px">
                              <div v-for="(data, index) of res.Depature" :key="index">
                                <v-timeline density="compact" side="end" truncate-line="both">
                                  <v-timeline-item class="mb-4" dot-color="grey" size="15">
                                    <div class="d-flex justify-space-between flex-grow-1">
                                      <div>
                                        <span style="
                                          font-size: 13px;
                                          font-weight: 500;
                                        ">
                                          {{ getdated(data.Departure.Date) }}
                                        </span>
                                      </div>
                                    </div>
                                  </v-timeline-item>

                                  <v-timeline-item fill-dot class="mb-12" dot-color="info" size="large">
                                    <template v-slot:icon>
                                      <span class="small">{{
                                        data.FlightDetail.FlightDuration.Value
                                      }}</span>
                                    </template>
                                    <div class="row g-2 mt-3">
                                      <div class="col-md-5 mt-2">
                                        <div class="row">
                                          <div class="col-sm-4">
                                            <v-img :src="data.MarketingCarrier.logo" width="40px"></v-img>
                                          </div>
                                          <div class="col-sm-8">
                                            <p style="
                                              font-size: 14px;
                                              font-weight: 500;
                                            ">
                                              {{ data.MarketingCarrier.Name }}
                                              <br />
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier.FlightNumber
                                              }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-7">
                                        <div class="row">
                                          <div class="row">
                                            <div class="col-md-3" align="center">
                                              <div style="
                                                font-size: 15px;
                                                font-weight: 600;
                                              ">
                                                {{ getflightdep(data) }}
                                              </div>
                                            </div>
                                            <div class="col-md-9 content" style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            ">
                                              {{ data.Departure.AirportCode }} ,
                                              {{ data.Departure.AirportName }}

                                              <v-tooltip activator="parent" location="top">
                                                {{ data.Departure.AirportCode }} ,
                                                {{ data.Departure.AirportName }}
                                              </v-tooltip>
                                            </div>
                                          </div>
                                          <div class="row mt-3">
                                            <div class="col-md-3" align="center">
                                              <div style="
                                                font-size: 15px;
                                                font-weight: 600;
                                              ">
                                                {{ getflightarr(data) }}
                                              </div>
                                            </div>
                                            <div class="col-md-9 content" style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            ">
                                              {{ data.Arrival.AirportCode }} ,
                                              {{ data.Arrival.AirportName }}

                                              <v-tooltip activator="parent" location="top">
                                                {{ data.Arrival.AirportCode }} ,
                                                {{ data.Arrival.AirportName }}
                                              </v-tooltip>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="row mt-4 gx-0">
                                          <div class="col-md-5">
                                            <div>
                                              <div class="ms-3">
                                                <span style="color: #ccc">
                                                  <v-icon>
                                                    mdi-food-fork-drink
                                                  </v-icon>
                                                </span>
                                                <span style="
                                                  font-size: 13px;
                                                  font-weight: 500;
                                                ">
                                                  Meal provided
                                                </span>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-md-4">
                                            <div class="ms-3">
                                              <span style="color: #ccc">
                                                <v-icon>
                                                  mdi-seat-passenger
                                                </v-icon>
                                              </span>
                                              <span style="
                                                font-size: 13px;
                                                font-weight: 500;
                                              ">
                                                Economy
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </v-timeline-item>

                                  <v-timeline-item class="mb-4" dot-color="grey" size="15">
                                    <div class="d-flex justify-space-between flex-grow-1">
                                      <div>
                                        <span style="
                                          font-size: 13px;
                                          font-weight: 500;
                                        ">
                                          {{ getdated1(data.Arrival.Date) }}
                                        </span>
                                      </div>
                                    </div>
                                  </v-timeline-item>
                                </v-timeline>

                                <div v-if="res.Depature.length - 1 !== index" class="d-flex align-center m-2"
                                  style="background-color: antiquewhite">
                                  <span>
                                    <v-icon size="20">mdi-clock-outline</v-icon>
                                  </span>
                                  <span class="ps-3 pt-1" style="font-size:12px;">
                                    {{ data.layoverTimes }} Layover Time
                                  </span>
                                </div>
                              </div>
                            </v-container>
                          </v-card>
                        </div>


                        <div v-if="res.Flightdepature">
                          <v-card class="mt-4">
                            <div class="pa-5">
                              <div style="
                                font-size: 15px;
                                font-weight: 500;
                                background-color: #e8f0fd;
                                padding: 5px;
                              ">
                                <span>RETURN</span>
                                <span style="margin-left: 10px">
                                  Total Trip Duration :
                                  {{ res.Returnjourney.Time }}</span>
                              </div>
                            </div>

                            <v-container style="max-width: 100%; padding: 0px 15px 15px 5px">
                              <div v-for="(data, index) of res.Return" :key="index">
                                <v-timeline density="compact" side="end" truncate-line="both">
                                  <v-timeline-item class="mb-4" dot-color="grey" size="15">
                                    <div class="d-flex justify-space-between flex-grow-1">
                                      <div>
                                        <span style="
                                          font-size: 13px;
                                          font-weight: 500;
                                        ">
                                          {{ getdated2(data.Departure.Date) }}
                                        </span>
                                      </div>
                                    </div>
                                  </v-timeline-item>

                                  <v-timeline-item fill-dot class="mb-12" dot-color="info" size="large">
                                    <template v-slot:icon>
                                      <span class="small">{{
                                        data.FlightDetail.FlightDuration.Value
                                      }}</span>
                                    </template>
                                    <div class="row g-2 mt-3">
                                      <div class="col-md-5 mt-2">
                                        <div class="row">
                                          <div class="col-sm-4">
                                            <v-img :src="data.MarketingCarrier.logo" width="40px"></v-img>
                                          </div>
                                          <div class="col-sm-8">
                                            <p style="
                                              font-size: 14px;
                                              font-weight: 500;
                                            ">
                                              {{ data.MarketingCarrier.Name }}
                                              <br />
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier.FlightNumber
                                              }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-7">
                                        <div class="row">
                                          <div class="row">
                                            <div class="col-md-3" align="center">
                                              <div style="
                                                font-size: 15px;
                                                font-weight: 600;
                                              ">
                                                {{ getflightdep1(data) }}
                                              </div>
                                            </div>
                                            <div class="col-md-9 content" style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            ">
                                              {{ data.Departure.AirportCode }}
                                              ,
                                              {{ data.Departure.AirportName }}

                                              <v-tooltip activator="parent" location="top">
                                                {{ data.Departure.AirportCode }}
                                                ,
                                                {{ data.Departure.AirportName }}
                                              </v-tooltip>
                                            </div>
                                          </div>
                                          <div class="row mt-3">
                                            <div class="col-md-3" align="center">
                                              <div style="
                                                font-size: 15px;
                                                font-weight: 600;
                                              ">
                                                {{ getflightarrival(data) }}
                                              </div>
                                            </div>
                                            <div class="col-md-9 content" style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            ">
                                              <v-tooltip activator="parent" location="top">
                                                {{ data.Arrival.AirportCode }} ,
                                                {{ data.Arrival.AirportName }}
                                              </v-tooltip>

                                              {{ data.Arrival.AirportCode }} ,
                                              {{ data.Arrival.AirportName }}
                                            </div>
                                          </div>
                                        </div>

                                        <div class="row mt-4 gx-0">
                                          <div class="col-md-5">
                                            <div>
                                              <div class="ms-3">
                                                <span style="color: #ccc">
                                                  <v-icon>
                                                    mdi-food-fork-drink
                                                  </v-icon>
                                                </span>
                                                <span style="
                                                  font-size: 13px;
                                                  font-weight: 500;
                                                ">
                                                  Meal provided
                                                </span>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-md-4">
                                            <div class="ms-3">
                                              <span style="color: #ccc">
                                                <v-icon>
                                                  mdi-seat-passenger
                                                </v-icon>
                                              </span>
                                              <span style="
                                                font-size: 13px;
                                                font-weight: 500;
                                              ">
                                                Economy
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </v-timeline-item>

                                  <v-timeline-item class="mb-4" dot-color="grey" size="15">
                                    <div class="d-flex justify-space-between flex-grow-1">
                                      <div>
                                        <span style="
                                          font-size: 13px;
                                          font-weight: 500;
                                        ">
                                          {{ getdated3(data.Arrival.Date) }}
                                        </span>
                                      </div>
                                    </div>
                                  </v-timeline-item>
                                </v-timeline>

                                <div v-if="index !== res.Return.length - 1" class="d-flex align-center m-2"
                                  style="background-color: antiquewhite">
                                  <span>
                                    <v-icon size="20">mdi-clock-outline</v-icon>
                                  </span>
                                  <span class="ps-3 pt-1" style="font-size:12px;">
                                    {{ data.layoverTimes }} Layover Time
                                  </span>
                                </div>
                              </div>
                            </v-container>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>











            <div class="mt-3" v-for="(res, index) of onewayresulted" :key="index">
              <div class="hoveredCard">
                <v-card rounded="0" style="padding: 20px 0px">
                  <!-- <div class="air-title">
                <h6 style="margin-left: 15px">
                  Unlock Phone deals upto USD 100. Call On +1 416642222
                </h6>
              </div> -->

                  <div class="row m-1">
                    <div class="col-lg-9">
                      <div class="mt-2">
                        <v-card style="overflow: unset; padding: 1px">
                          <div class="row mt-4 g-0 mb-2">
                            <div class="col-md-3 col-12 mb-3 mb-md-0">
                              <div class="row ms-1">
                                <div class="col-4 airline-logo">
                                  <v-img :src="getlogo(res.Depature)" width="30px"></v-img>
                                </div>
                                <div class="col-8 ps-0 ps-md-2">
                                  <p class="res-name">
                                    {{ getname(res.Depature) }}

                                    <br />
                                    {{ getid(res.Depature) }} -
                                    {{ getNumber(res.Depature) }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-2 col-2" align="center">
                              <p class="res-time">
                                <span style="font-weight: 400">{{
                                  getDate(res.Depature)
                                }}</span>
                                <br />
                                <span style="font-weight: 400; font-size: 18px">{{ getTime(res.Depature) }}
                                </span>
                              </p>
                            </div>

                            <div class="col-md-1 col-2 d-flex align-center justify-center">
                              <p class="res-time" style="margin-bottom: 4px; font-size: 18px">
                                {{ getAirportCode(res.Depature) }}
                              </p>
                            </div>



                            <div class="col-md-3 col-4 d-flex flex-column justify-center"
                              v-if="res.Depaturejourney.Stops == 0">
                              <p class="res-stop m-0 text-center">Direct</p>
                              <div class="d-flex align-center justify-center">
                                <hr style="width:87%; border:1px solid black;">
                                <v-icon color="grey"
                                  style="font-size:22px; height:15px; position:relative; right:3px; transform: rotate(90deg) !important;">
                                  mdi-airplane</v-icon>
                              </div>

                              <div class="d-flex justify-center time-section">
                                <v-icon color="grey">mdi-clock-outline </v-icon>
                                <span>{{ res.Depaturejourney.Time }}</span>
                              </div>
                            </div>




                            <div class="col-md-3 col-4 d-flex flex-column justify-center"
                              v-if="res.Depaturejourney.Stops == 1">
                              <p class="res-stop m-0 text-center">{{ res.Depaturejourney.Stops }} Stops</p>


                              <div class="d-flex align-center justify-center" style="position: relative;">
                                <hr style="width:45%; border:1px solid black;">

                                <v-icon size="10" @mouseover="showDetails1(res, index)"
                                  @mouseout="hideDetails1(res)">mdi-circle</v-icon>

                                <div v-if="res.Depature.length >= 2" class="hoverData1" id="tooltip"
                                  :class="{ visible: res.hoveredName1 }">
                                  <div style="text-align: center"></div>
                                  <div>
                                    <span style="font-size: 12px">{{
                                      getarrivaldata(res.Depature)
                                    }}</span>
                                    <span> | </span>
                                    <span style="font-size: 12px">{{
                                      getarrtime(res.Depature)
                                    }}</span>
                                  </div>
                                </div>

                                <hr style="width:32%; border:1px solid black;">

                                <v-icon color="grey" size="22"
                                  style="position:relative; right:3px; transform: rotate(90deg) !important;">
                                  mdi-airplane</v-icon>
                              </div>

                              <div class="d-flex justify-center time-section">
                                <v-icon color="grey">mdi-clock-outline </v-icon>
                                <span>{{ res.Depaturejourney.Time }}</span>
                              </div>
                            </div>




                            <div class="col-md-3 col-4 d-flex flex-column justify-center"
                              v-if="res.Depaturejourney.Stops >= 2">
                              <p class="res-stop m-0 text-center">{{ res.Depaturejourney.Stops }} Stops</p>


                              <div class="d-flex align-center justify-center" style="position: relative;">
                                <hr style="width:40%; border:1px solid black;">

                                <v-icon size="10" @mouseover="showDetails1(res, index)"
                                  @mouseout="hideDetails1(res)">mdi-circle</v-icon>

                                <div v-if="res.Depature.length >= 2" class="hoverDataStop1" id="tooltip"
                                  :class="{ visible: res.hoveredName1 }">
                                  <div style="text-align: center"></div>
                                  <div>
                                    <span style="font-size: 12px">{{
                                      getarrivaldata(res.Depature)
                                    }}</span>
                                    <span> | </span>
                                    <span style="font-size: 12px">{{
                                      getarrtime(res.Depature)
                                    }}</span>
                                  </div>
                                </div>

                                <hr style="width:15%; border:1px solid black;">

                                <v-icon size="10" @mouseover="showDetails2(res, index)"
                                  @mouseout="hideDetails2(res)">mdi-circle</v-icon>
                                <div v-if="res.Depature.length >= 3" class="hoverDataStop2" id="tooltip"
                                  :class="{ visible: res.hoveredName2 }">
                                  <div style="text-align: center"></div>
                                  <div>
                                    <span style="font-size: 12px">{{
                                      get1arrtime(res.Depature, index)
                                    }}</span>
                                    <span> | </span>
                                    <span style="font-size: 12px">{{
                                      get2arrtime(res.Depature)
                                    }}</span>
                                  </div>
                                </div>

                                <hr style="width:25%; border:1px solid black;">
                                <v-icon size="22" color="grey"
                                  style="position:relative; right:3px; transform: rotate(90deg) !important;">
                                  mdi-airplane</v-icon>
                              </div>

                              <div class="d-flex justify-center time-section">
                                <v-icon color="grey">mdi-clock-outline </v-icon>
                                <span>{{ res.Depaturejourney.Time }}</span>
                              </div>
                            </div>












                            <div class="col-md-1 col-2 d-flex align-center justify-center">
                              <p class="res-time" style="margin-bottom: 4px; font-size: 18px">
                                {{ getArrivalCode(res.Depature) }}
                              </p>
                            </div>

                            <div class="col-md-2 col-2" align="center">
                              <p class="res-time">
                                <span style="font-weight: 400">
                                  {{ getArrivaldate(res.Depature) }}</span>
                                <br />
                                <span style="font-weight: 400; font-size: 18px">{{ getArrivalTime(res.Depature) }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </v-card>
                      </div>


                      <!-- Seat................ -->

                      <div class="row d-flex mt-3">

                        <div class="col-md-3" align="center">
                          <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                          <span style="font-size: 12px; color: gray">{{
                            getdepseat(res.Depature)
                          }}
                            Seats</span>
                        </div>


                        <div class="col-md-3 d-flex align-center" align="center">
                          <span><v-icon size="20">mdi-cash</v-icon></span>
                          <div>
                            <span style="
      font-size: 11px;
      color: gray;
      padding-left: 5px;
    ">{{ res.Refund }}</span>
                          </div>
                        </div>



                        <div class="col-md-3" align="center">
                          <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                          <span style="font-size: 12px; color: gray">{{
                            this.oneway.class
                          }}</span>
                        </div>


                        <div class="col-md-3 d-flex align-center" @click="flighted2(res, index)" align="center">
                          <span style="
    color: #36c;
    font-size: 12px;
    margin-top: 2px;
    cursor: pointer;
  ">
                            Flight Details<span style="position: relative; right: 3px; top: 1px">
                              <span><v-icon>mdi-chevron-down</v-icon></span>
                            </span>
                          </span>
                        </div>

                      </div>


                      <!-- Seat End................ -->


                    </div>

                    <div class="col-lg-3 d-flex align-center justify-center">
                      <div class="d-flex flex-column align-center">
                        <h3 style="color: navy">USD {{ getprice(res) }}</h3>
                        <!-- <p style="font-size: 14px">
                          Price per person incl.taxes & fees
                        </p> -->
                        <v-btn color="#5191fa" width="150px" style="color: white"
                          @click.prevent="selected(res, index)">Select</v-btn>
                      </div>
                    </div>

                    <div class="col-md-9 col-12">
                      <div>
                        <div v-if="res.Flightdepature">
                          <v-card class="mt-4">
                   
                            <div class="pa-2">
                              <div style="
                                font-size: 15px;
                                font-weight: 500;
                                background-color: #e8f0fd;
                                padding: 5px;
                              ">
                                <span>DEPARTURE</span>
                                <span style="margin-left: 10px">
                                  Total Trip Duration :
                                  {{ res.Depaturejourney.Time }}</span>
                              </div>
                            </div>

                            <v-container style="max-width: 100%; padding: 0px 15px 15px 5px">
                              <div v-for="(data, index) of res.Depature" :key="index">
                                <v-timeline density="compact" side="end" truncate-line="both">
                                  <v-timeline-item class="mb-4" dot-color="grey" size="15">
                                    <div class="d-flex justify-space-between flex-grow-1">
                                      <div>
                                        <span style="
                                          font-size: 13px;
                                          font-weight: 500;
                                        ">
                                          {{ getdated(data.Departure.Date) }}
                                        </span>
                                      </div>
                                    </div>
                                  </v-timeline-item>

                                  <v-timeline-item fill-dot class="mb-12" dot-color="info" size="large">
                                    <template v-slot:icon>
                                      <span class="small">{{
                                        data.FlightDetail.FlightDuration.Value
                                      }}</span>
                                    </template>
                                    <div class="row g-2 mt-3">
                                      <div class="col-md-5 mt-2">
                                        <div class="row">
                                          <div class="col-sm-4">
                                            <v-img :src="data.MarketingCarrier.logo" width="40px"></v-img>
                                          </div>
                                          <div class="col-sm-8">
                                            <p style="
                                              font-size: 14px;
                                              font-weight: 500;
                                            ">
                                              {{ data.MarketingCarrier.Name }}
                                              <br />
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier.FlightNumber
                                              }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-7">
                                        <div class="row">
                                          <div class="row">
                                            <div class="col-md-3" align="center">
                                              <div style="
                                                font-size: 15px;
                                                font-weight: 600;
                                              ">
                                                {{ getflightdep(data) }}
                                              </div>
                                            </div>
                                            <div class="col-md-9 content" style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            ">
                                              {{ data.Departure.AirportCode }} ,
                                              {{ data.Departure.AirportName }}

                                              <v-tooltip activator="parent" location="top">
                                                {{ data.Departure.AirportCode }} ,
                                                {{ data.Departure.AirportName }}
                                              </v-tooltip>
                                            </div>
                                          </div>
                                          <div class="row mt-3">
                                            <div class="col-md-3" align="center">
                                              <div style="
                                                font-size: 15px;
                                                font-weight: 600;
                                              ">
                                                {{ getflightarr(data) }}
                                              </div>
                                            </div>
                                            <div class="col-md-9 content" style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            ">
                                              {{ data.Arrival.AirportCode }} ,
                                              {{ data.Arrival.AirportName }}

                                              <v-tooltip activator="parent" location="top">
                                                {{ data.Arrival.AirportCode }} ,
                                                {{ data.Arrival.AirportName }}
                                              </v-tooltip>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="row mt-4 gx-0">
                                          <div class="col-md-5">
                                            <div>
                                              <div class="ms-3">
                                                <span style="color: #ccc">
                                                  <v-icon>
                                                    mdi-food-fork-drink
                                                  </v-icon>
                                                </span>
                                                <span style="
                                                  font-size: 13px;
                                                  font-weight: 500;
                                                ">
                                                  Meal provided
                                                </span>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-md-4">
                                            <div class="ms-3">
                                              <span style="color: #ccc">
                                                <v-icon>
                                                  mdi-seat-passenger
                                                </v-icon>
                                              </span>
                                              <span style="
                                                font-size: 13px;
                                                font-weight: 500;
                                              ">
                                                Economy
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </v-timeline-item>

                                  <v-timeline-item class="mb-4" dot-color="grey" size="15">
                                    <div class="d-flex justify-space-between flex-grow-1">
                                      <div>
                                        <span style="
                                          font-size: 13px;
                                          font-weight: 500;
                                        ">
                                          {{ getdated1(data.Arrival.Date) }}
                                        </span>
                                      </div>
                                    </div>
                                  </v-timeline-item>
                                </v-timeline>

                                <div v-if="res.Depature.length - 1 !== index" class="d-flex align-center m-2"
                                  style="background-color: antiquewhite">
                                  <span class="ps-3 pt-1" style="font-family: serif">
                                    {{ data.layoverTimes }}
                                  </span>
                                </div>
                              </div>
                            </v-container>
                          </v-card>
                        </div>
      
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
































          </div>
        </div>
      </div>
    </div>
  </div>



  <div v-if="searchloader">
    <loader></loader>
  </div>
</template>

<script>
import loader from "@/components/loaderComponent.vue";
// import onewayService from '@/service/onewayService'
import multicityService from "@/service/multicityService";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
import userData from "@/airport_icao.json";
import axios from "axios";
import airLogo from "@/airlinesLogo.json";
const totalPricefilter = require("lodash");
export default {
  components: {
    VueDatePicker,
    AutoComplete,
    loader,
  },

  data() {
    return {
      headerStyles: ["headerCom", "headerCom1", "headerCom2", "headerCom3"],
      footerStyles: ["footerCom", "footerCom1", "footerCom2", "footerCom3"],

      selectedHeader: "headerCom1",
      selectedFooter: "footerCom",

      vj: "",
      modifySection: false,
      FlightDetail: false,
      FlightDetail1: false,
      dateFormat: " dd MMM, yyyy",
      detail: {
        from: "",
        to: "",
        dedate: "",
        redate: "",
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },
      items: [],
      airlogodata: [],
      oneway1: false,
      roundtrip: false,
      multitrip: false,

      searchresult: false,
      searchloader: true,

      isIcon: true,
      date1: "",
      date2: "",
      value: "1",
      class: "Business",
      travels: false,
      red: 1,
      red1: 1,


      roundModify: true,
      onewayModify: false,


      aduls: 1,
      aduls1: 0,
      aduls2: 0,
      addition: 0,
      select: "Business",
      returnData: [],
      Oneway: false,
      rount: true,
      multicity: false,
      airData: [],
      flight: [],
      flight1: [],
      flightTime: [],
      flightseg: [],
      segkey: [],
      SegmentKey: [],
      arriv: [],
      dep: [],
      splitValues: [],
      splitValues1: [],
      seats: [],
      seg: [],
      Journey: [],
      Journey1: [],
      flightsegmet: [],
      flightsegmetkey: [],
      datas: [],
      journeyData: [],
      changevalue: [],
      steps: null,
      value1: [0, 100],
      value2: [0, 24],
      datasource: [],
      air: [],
      airnam: [],
      airnumber: [],
      airflight: [],
      flightprice: [],
      price: [],
      flightdate: [],
      time: [],
      arrivaltime: [],
      Renuvable: [],
      vasan: [],
      vasan1: [],
      totalprice: [],
      segmentref: [],
      stop: [],
      time1: [],
      multicityData: [],
      aircode: [],
      depcode: [],
      airname: [],
      depname: [],
      airtime: [],
      deptime: [],
      airid: [],
      airnum: [],
      airstop: [],
      offerpay: [],
      airstime: [],
      airfname: [],
      airdate: [],
      depdate: [],
      airref: [],
      airprice: [],
      sortingPrice: [],
      depnamedate: [],
      localData: [],
      rountresult: [],
      filterData: [],
      onewayresulted: [],
      onewayfilterData: [],
      routtime: [],
      airportname: "",
      traveltime: "",
      Adult: [],
      Child: [],
      Infrant: [],
      maximum: null,
      minimum: null,
      countries: [{ name: "Australia", code: "AU" }],
      selectedCountry: "",
      loading: false,
      fromAirports: [],
      airitems: [],
      oneway: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },

      tripData: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },

      responseData: [],
      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,

      hoveredName: false,
      hoverDetail: "Hello....",

      NonStopFil: false,
      OneStopFil: false,
      OnePlusFil: false,

      nonStop: [],
      oneStop: [],
      onePlusStop: [],

      filNonstop: [],
      filOneStop: [],
      filOnePlusStop: [],
      concatStops: [],
      // layoverTimes: [],
      // layoverTimes1: [],
      input: [
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          // clicked3:false,
        },
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          // clicked3:false,
        },
      ],

      resulted: [],
      onewaydata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-10-19",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ONEWAY",
              CabinType: "Y",
              Currency: "CAD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      Rountdata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-08-25",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                  {
                    Departure: {
                      AirportCode: "BOM",
                      Date: "2023-10-27",
                    },
                    Arrival: {
                      AirportCode: "MAA",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ROUND",
              CabinType: "Y",
              Currency: "CAD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },
      itinaryRequest: {
        SearchClass: "",
        OriginCity1: "YVR",
        DestinationCity1: "SEL",
        OriginCity2: "SEL",
        DestinationCity2: "YVR",
        TripType: "RoundTrip",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        Slice2: "2,3",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },

      itinaryRequestoneway: {
        SearchClass: "",
        OriginCity1: "YVR",
        DestinationCity1: "SEL",
        OriginCity2: "SEL",
        DestinationCity2: "YVR",
        TripType: "OnewayTrip",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        Slice2: "2,3",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },
    };
  },
  methods: {
    showDetails1(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
    },
    hideDetails1(res) {
      res.hoveredName1 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails2(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName2 = true;
        } else {
          v.hoveredName2 = false;
        }
      });
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
    },
    hideDetails2(res) {
      res.hoveredName2 = false;
    },

    showDetails3(res, index) {
      this.rountresult.map((v, i) => {
        // console.log(v, "ppppp");
        if (i == index) {
          v.hoveredName3 = true;
        } else {
          v.hoveredName3 = false;
        }
      });
    },
    hideDetails3(res) {
      res.hoveredName3 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails4(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName4 = true;
        } else {
          v.hoveredName4 = false;
        }
      });
    },
    hideDetails4(res) {
      res.hoveredName4 = false;
    },
    adulted() {
      this.Adult.push({
        PassengerID: "T1",
        PTC: "ADT",
      });

      // console.log(this.Adult, "add");
    },
    adulted1() {
      this.Adult.pop();

      // console.log(this.Adult, "remove");
    },

    Childed() {
      this.Child.push({
        PassengerID: "T1",
        PTC: "CHD",
      });
    },
    Childed1() {
      this.Child.pop();
    },
    Infranted() {
      this.Infrant.push({
        PassengerID: "T1",
        PTC: "INF",
      });
    },
    Infranted1() {
      this.Infrant.pop();
    },

    act(data) {
      this.red1 = data;
      this.oneway.city = this.red1;

      if (this.red1 == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
      }
      if (this.red1 == 1) {
        this.Oneway = false;
        this.rount = true;
        this.multicity = false;

      }
      // if (this.red1 == 3) {
      //   this.Oneway = false;
      //   this.rount = false;
      //   this.multicity = true;
      // }
    },

    actActive() {
      console.log(this.red1, 'TRTRTRTR1')
      if (this.red1 == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
        this.roundModify = false;
        this.onewayModify = true;
      }

      if (this.red1 == 1) {
        this.Oneway = false;
        this.rount = true;
        this.multicity = false;
        this.roundModify = true;
        this.onewayModify = false;
      }
    },


    deg1() {
      this.adulted1();
      if (this.aduls == this.aduls2 && this.aduls2 > 1) {
        this.deg3();
      }
      if (this.aduls > 1) {
        this.aduls = this.aduls - 1;

        this.added();
      }
    },
    add1() {
      if (this.aduls < 9 && this.addition < 9) {
        this.aduls += 1;

        // console.log(this.aduls, "1111");

        this.adulted();
        this.added();
      }
    },

    deg2() {
      if (this.aduls1 > 0) {
        this.aduls1 = this.aduls1 - 1;

        this.Childed1();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.aduls1 < 8 && this.addition < 9) {
        this.aduls1 = this.aduls1 + 1;

        // console.log(this.aduls1, "2222");

        this.Childed();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.aduls2 > 0) {
        this.aduls2 = this.aduls2 - 1;

        this.Infranted1();
        this.added();
      }
    },
    add3() {
      if (this.addition < 9 && this.aduls > this.aduls2) {
        this.aduls2 = this.aduls2 + 1;

        // console.log(this.aduls2, "3333");

        this.Infranted();
        this.added();
      }
    },

    added() {
      this.addition = this.aduls + this.aduls1 + this.aduls2;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(
        this.Infrant
      );

      // console.log(this.concatenatedArray, "concatenatedArray");
    },

    localdone() {
      localStorage.setItem("value1", "this.aduls");
    },

    closeDropdown() {
      this.$refs.countrySelect.blur(); // Close the dropdown
    },

    send() {
      if (this.input.length < 4) {
        this.input.push({
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        });

        if (this.input.length == 5) {
          this.addmore = false;
        }
      }
      if (this.input.length > 2) {
        this.clearall = true;
      }

      for (let i = 1; i < this.input.length; i++) {
        this.input[i].from = this.input[i - 1].to;
      }
    },

    delet(index) {
      this.input.splice(index, 1);
    },
    getprice(res) {
      return (res.price + res.Taxprice).toFixed(2);
    },

    getdepseat(data) {
      // console.log(data,'ppppppppppppppppppppppp')
      return data[0].Seat;
    },

    getretseat(data) {
      return data[0].Seat;
    },
    getlogo($event) {
      return $event[0].MarketingCarrier.logo;
    },
    getlogo1($event) {
      return $event[0].MarketingCarrier.logo;
    },
    getname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getDate($event) {
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },
    getAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getarrivaldata($event) {
      return $event[0].Arrival.AirportName;
    },
    getreturndata($event) {
      return $event[0].Arrival.AirportName;
    },
    getarrtime($event) {
      return $event[0].Arrival.Time;
    },
    getreturntime($event) {
      return $event[0].Arrival.Time;
    },
    get1arrtime($event) {
      // console.log($event,index,'$event')

      return $event[$event.length - 1].Arrival.AirportName;
    },
    get1returntime($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },

    get2arrtime($event) {
      return $event[$event.length - 1].Arrival.Time;
    },

    get2returntime($event) {
      return $event[$event.length - 1].Arrival.Time;
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },
    getArrivaldate($event) {
      return moment($event[$event.length - 1].Arrival.Date).format(
        "ddd MMM DD"
      );
    },

    getArrivalCode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getreturnname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getreturnNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getreturnid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getreturnDate($event) {
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },
    getdeparDate($event) {
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdated($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated1($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated2($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getreturnAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getreturnTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getreturnArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },
    getreturnArrivaldate($event) {
      return moment($event[$event.length - 1].Arrival.Date).format(
        "ddd, MMM DD"
      );
    },
    getreturnArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    //     getlayover(data) {
    //       this.layoverTimes1 = [];

    // let $event=data
    // let layoverTime=[]
    // for (let i = 0; i <= $event.length-1; i++) {
    //   console.log($event[i],'data.length - 1')

    //   const currentIndex = i;
    //   const nextIndex = (i + 1) % data.length;

    //   const time1 = new Date(
    //     `${data[currentIndex].Arrival.Date}T${data[currentIndex].Arrival.Time}`
    //   );
    //   const time2 = new Date(
    //     `${data[nextIndex].Departure.Date}T${data[nextIndex].Departure.Time}`
    //   );

    //   const diffInMilliseconds = Math.abs(time2 - time1);
    //   const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

    //   const hours = Math.floor(diffInMinutes / 60);
    //   const minutes = diffInMinutes % 60;

    //     layoverTime .push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`) ;

    // }

    // this.layoverTimes1=layoverTime
    // console.log(this.layoverTimes1,'0000000000000000000')
    //     },

    //     getlayoverdep(data) {
    //       console.log(data,'indexxxxx')
    //       this.layoverTimes= [];
    // let $event=data
    // let layoverTime=[]
    // for (let i = 0; i <= $event.length-1; i++) {
    //   console.log($event[i],'data.length - 1')

    //   const currentIndex = i;
    //   const nextIndex = (i + 1) % data.length;

    //   const time1 = new Date(
    //     `${data[currentIndex].Arrival.Date}T${data[currentIndex].Arrival.Time}`
    //   );
    //   const time2 = new Date(
    //     `${data[nextIndex].Departure.Date}T${data[nextIndex].Departure.Time}`
    //   );

    //   const diffInMilliseconds = Math.abs(time2 - time1);
    //   const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

    //   const hours = Math.floor(diffInMinutes / 60);
    //   const minutes = diffInMinutes % 60;

    //     layoverTime .push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`) ;

    // }

    // this.layoverTimes=layoverTime
    // console.log(this.layoverTimes,'0000000000000000000')
    // },

    closeRet(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.Flightreturn = false;
        }
      });
    },

    flighted1(res, index) {
      this.rountresult.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
    },
    flighted2(index,res) {
      console.log(index,res)
      this.onewayresulted.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
    
    },
    closeDep(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
    },

    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightdep1(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarrival(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    onewayselect(result) {
      let res = "";
      if (this.oneway.from.name) {
        // rountdetail.from = this.oneway.from.name;
        res = this.oneway.from.name.split(" ");
        this.roundfrom = res[res.length - 1].replace("(", "").replace(")", "");
      } else {
        // rountdetail.from = this.oneway.from;
        res = this.oneway.from.split(" ");
        this.roundfrom = res[res.length - 1].replace("(", "").replace(")", "");
      }

      let res1 = "";
      if (this.oneway.to.name) {
        // rountdetail.to = this.oneway.to.name;
        res1 = this.oneway.to.name.split(" ");
        this.roundto = res1[res1.length - 1].replace("(", "").replace(")", "");
      } else {
        // rountdetail.to = this.oneway.to;
        res1 = this.oneway.to.split(" ");
        this.roundto = res1[res1.length - 1].replace("(", "").replace(")", "");
      }
      console.log(this.oneway, "this.onewaythis.onewaythis.oneway");

      let $data = localStorage.getItem("rountData");
      let $data1 = JSON.parse($data);
      this.itinaryRequestoneway.SearchClass = $data1.class;
      this.itinaryRequestoneway.OriginCity1 = this.roundfrom;
      this.itinaryRequestoneway.DestinationCity1 = this.roundto;
      this.itinaryRequestoneway.OriginCity2 = this.roundto;
      this.itinaryRequestoneway.DestinationCity2 = this.roundfrom;
      this.itinaryRequestoneway.DisplayedPrice = result.TotalAmount;
      this.itinaryRequestoneway.Adult = $data1.adult;
      this.itinaryRequestoneway.Child = $data1.child;
      this.itinaryRequestoneway.InfantLap = $data1.infrant;

      let depature = result.Depature;

      let indexdata = [];
      depature.forEach((v, i) => {
        this.itinaryRequestoneway[`Carrier${i + 1}`] =
          v.MarketingCarrier.AirlineID;
        this.itinaryRequestoneway[`Cabin${i + 1}`] = $data1.class;
        this.itinaryRequestoneway[`FlightNumber${i + 1}`] =
          v.MarketingCarrier.FlightNumber;
        this.itinaryRequestoneway[`DepartureDate${i + 1}`] = moment(
          v.Departure.Date
        ).format("YYYY-MM-DD");
        this.itinaryRequestoneway[`DepartureTime${i + 1}`] = moment(
          v.Departure.Time,
          "HH:mm:ss"
        ).format("HH:mm");
        this.itinaryRequestoneway[`ArrivalDate${i + 1}`] = moment(
          v.Arrival.Date
        ).format("YYYY-MM-DD");
        this.itinaryRequest[`ArrivalTime${i + 1}`] = moment(
          v.Arrival.Time,
          "HH:mm:ss"
        ).format("HH:mm");
        this.itinaryRequestoneway[`Origin${i + 1}`] = v.Departure.AirportCode;
        this.itinaryRequestoneway[`Destination${i + 1}`] =
          v.Arrival.AirportCode;
        this.itinaryRequestoneway[`BookingCode${i + 1}`] =
          v.Code.ResBookDesigCode;
        if (i <= result.Depature.length - 1) {
          indexdata.push(i + 1);
        }
      });
      this.itinaryRequestoneway.Slice1 = indexdata.toString();

      let resultData = {
        hit_id: "ca-650932b2905438-25549374-2685",
        post_data: this.itinaryRequestoneway,
        http_data: "-",
        ref_url:
          "http://gfs.metaapi1309/gflights?SearchClass=Economy&OriginCity1=YVR&DestinationCity1=SELOriginCity2=SEL&DestinationCity2=YVR&TripType=RoundTrip&PointOfSaleCountry=CA&DisplayedPrice=5915.24&DisplayedPriceCurrency=CAD&UserLanguage=en&UserCurrency=CAD&Adult=1&Child=0&InfantLap=0&Slice1=1&Slice2=2%2C3&Cabin1=Economy&Carrier1=OZ&DepartureDate1=2023-12-21&DepartureTime1=12%3A05&ArrivalDate1=2023-12-22&ArrivalTime1=17%3A00&Origin1=YVR&Destination1=ICN&BookingCode1=K&FlightNumber1=6101&Cabin2=Economy&Carrier2=OZ&DepartureDate2=2024-01-10&DepartureTime2=20%3A50&ArrivalDate2=2024-01-10&ArrivalTime2=14%3A00&Origin2=ICN&Destination2=SFO&BookingCode2=E&FlightNumber2=212&Cabin3=Economy&Carrier3=AC&DepartureDate3=2024-01-10&DepartureTime3=16%3A35&ArrivalDate3=2024-01-10&ArrivalTime3=19%3A00&Origin3=SFO&Destination3=YVR&BookingCode3=S&FlightNumber3=571&mTime=2023-09-06T12%3A49%3A58.885351Z&ugid=43a49112-d6a5-4b03-970a-f4d126a1deb1&ReferralId=btgfs",
        req_url: "gfs.metaapi1309/flights/itinerary",
        IP_Address: "127.0.0.1",
        meta_portal_id: "btgfs",
      };
      // console.log(resultData)

      axios
        .post("https://vtapi.wistirna.com/api/hittable", resultData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response, "responseresponseresponseresponseresponse");
        });

      localStorage.removeItem("itineryData");
      let selectData = [];
      selectData.push(JSON.stringify(result));
      localStorage.setItem("onewayItineyData", selectData);

      // console.log(res, index, "abinapsyco");
      this.$router.push("/flightInfo");
    },

    multidata() {
      multicityService.getAllUser().then((v) => {
        this.offerpay = v.data.OffersGroup.AirlineOffers[0].Offer;

        this.Journey = v.data.DataLists.FlightList.Flight;

        this.Journey1 = v.data.DataLists.FlightSegmentList.FlightSegment;
        this.Journey.forEach((a) => {
          this.flightsegmet.push(a.SegmentReferences.split(" "));
        });
        this.Journey1.forEach((t) => {
          this.flightsegmetkey.push(t);
        });

        this.Journey.forEach((q) => {
          this.airstime.push(q.Journey.Time);
          this.airstop.push(q.Journey.Stops);
        });
        for (let i = 0; i <= this.offerpay.length - 1; i++) {
          let item = [];
          item = this.offerpay[i].OfferItem;
          item.forEach((t) => {
            this.airprice.push(
              t.TotalPriceDetail.TotalAmount.BookingCurrencyPrice
            );
            if (
              t.PassengerType === "ADT" ||
              t.PassengerType == "ChD" ||
              t.PassengerType == "INF"
            ) {
              if (t.Refundable === "true") {
                this.airref.push("Refundable");
              } else {
                this.airref.push("NON Refundable");
              }
            }
          });
        }

        this.airprice.forEach((c) => {
          this.multicityData.push({ airprice: c });
        });
      });
    },

    mydata() {
      try {
        this.resulted = JSON.parse(localStorage.getItem("myData"));
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },

    deatailData() {
      if (this.detail.city == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
        this.oneway1 = true;
      }
      if (this.detail.city == 1) {
        this.roundtrip = true;
        this.Oneway = false;
        (this.rount = true), (this.multicity = false);
      }
      if (this.detail.city == 3) {
        this.multitrip = true;
        this.Oneway = false;
        (this.rount = false), (this.multicity = true);
      }
    },

    // RoundTrip datePicker

    fromDateChanged(newValue) {
      this.oneway.dedate = newValue;

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.fromDateError = false;

        // console.log(this.oneway.dedate, "ddddddddd...1....eeeeeeeeeee");
      } else {
        this.clicked1 = false;
        this.fromDateError = true;

        // console.log(this.oneway.dedate, "ddddddddd...2....eeeeeeeeeee");
      }
    },

    ClickOutDepat() {
      this.$refs.dpRef1.closeMenu();

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    toDateChanged(newValue) {
      this.oneway.redate = newValue;
      // console.log(this.oneway.redate, "ttttttttttt");

      if (this.oneway.redate) {
        this.clicked2 = true;
        this.toDateError = false;
      } else {
        this.clicked2 = false;
        this.toDateError = true;
      }
    },

    ClickOutReturn() {
      this.$refs.dpRef2.closeMenu();
      // console.log(this.oneway.redate, "kkkkkkkkk");

      if (this.oneway.redate) {
        this.clicked2 = true;
      } else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End

    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.dedate = newValue;

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();
      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    multiDateChanged(index, newValue) {
      // console.log(index, newValue, "didididid...");
      this.departDate = newValue;

      this.input.map((v, i) => {
        if (index == i) {
          if (this.departDate) {
            v.clicked3 = true;
            v.multiDateError = false;
          } else {
            v.clicked3 = false;
            v.multiDateError = true;
          }
        }
      });
    },

    multiClickOutside() {
      this.input.clicked3 = false;
      this.datePickerIsOpen = false;
    },
    // Multicity datepicker End

    roundData() {
      let result = "";
      result = localStorage.getItem("rountData");

      let result1 = [];
      result1 = JSON.parse(result);
      this.oneway.class = result1.class;
      if (result1.from.name) {
        this.oneway.from = result1.from.name;
        this.tripData.from = result1.from.name;
      } else {
        this.oneway.from = result1.from;
        this.tripData.from = result1.from;
      }
      if (result1.to.name) {
        this.oneway.to = result1.to.name;
        this.tripData.to = result1.to.name;
      } else {
        this.oneway.to = result1.to;
        this.tripData.to = result1.to;
      }
      this.oneway.dedate = moment(result1.dedate).format("DD MMM-YYYY");
      this.tripData.dedate = moment(result1.dedate).format("DD MMM-YYYY");

      this.oneway.redate = moment(result1.redate).format("DD MMM-YYYY");
      this.tripData.redate = moment(result1.redate).format("DD MMM-YYYY");

      this.oneway.class = result1.class;
      this.tripData.class = result1.class;

      // console.log(this.oneway, "ppppppppppppppppppppqqqqqqqqqqqqqqq");
      this.aduls = result1.adult;
      this.tripData.aduls = result1.adult;

      this.aduls2 = result1.infrant;
      this.tripData.aduls2 = result1.infrant;

      this.aduls1 = result1.child;
      this.tripData.aduls1 = result1.child;

      this.select = result1.class;
      this.tripData.select = result1.class;
    },

    roundedtrip() {
      for (let i = 0; i < this.localData.length; i++) {
        if (this.localData[i]) {
          this.localData[i].forEach((v) => {
            // console.log(v.DataLists,'good')
            let seats = [];
            this.Journey = v.DataLists.FlightList.Flight;
            // console.log(this.Journey, "vasanthjourney");
            let price = [];
            let Tax = [];
            let pricedetail = [];
            let $data = v.OffersGroup.AirlineOffers[0].Offer;

            let arrsplit = [];
            let depsplit = [];
            let resultdata = [];
            let resultdata1 = [];
            let refund = [];
            $data.forEach((s) => {
              // console.log(s, 'pppppppppppppppp')
              price.push(s.TotalPrice.EquivCurrencyPrice);
              Tax.push(s.TaxPrice.EquivCurrencyPrice);
              pricedetail.push(s.OfferItem);
              s.OfferItem.forEach((v) => {
                resultdata = v.FareComponent[1].SegmentRefs;
                resultdata1 = v.FareComponent[0].SegmentRefs;
                if (v.Refundable === "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }
              });
              arrsplit.push(resultdata.split(" "));
              depsplit.push(resultdata1.split(" "));
            });
            // console.log(arrsplit, "$data");

            // console.log(refund, 'ppppppppppppppppppppppppppppppppp')
            this.Journey1 = v.DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              this.flightsegmet.push(a.SegmentReferences.split(" "));
              // console.log(this.flightsegmet ,'thesplitvalue')
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
              // console.log(this.flightsegmetkey, "vasanthjourney");
            });

            seats = v.DataLists.PriceClassList.PriceClass;
            seats.forEach((c) => {
              c.ClassOfService.forEach((v) => {
                for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
                  if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
                    this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
                  }
                }
              });
            });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              // console.log(depsplit,'depsplit')
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.rountresult.push({ Depature: seg });
                }
              });
            }

            for (let i = 0; i <= arrsplit.length - 1; i++) {
              // console.log(arrsplit,'arrsplit')
              let seg = [];
              arrsplit[i].forEach((f, index) => {
                this.items = this.flightsegmetkey.filter(
                  (v) => v.SegmentKey == f
                );
                if (this.items.length > 0) {
                  seg.push(this.items[0]);
                }
                if (index == arrsplit[i].length - 1) {
                  this.returnData.push(seg);
                }
              });
            }
            let Arrjourney = [];
            let depjourney = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0].FareComponent[0];
              let result1 = $data[j].OfferItem[0].FareComponent[1];
              let source = [];
              let source1 = [];
              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                  // console.log(source, 'source')
                } else if (
                  result1.SegmentRefs == this.Journey[i].SegmentReferences
                ) {
                  source1 = this.Journey[i].Journey;
                }
              }
              Arrjourney.push(source);
              depjourney.push(source1);
            }

            for (let i = 0; i <= this.rountresult.length - 1; i++) {
              this.rountresult[i].price = price[i];
              this.rountresult[i].Taxprice = Tax[i];
              this.rountresult[i].pricedetail = pricedetail[i];
              this.rountresult[i].Return = this.returnData[i];
              this.rountresult[i].Flightreturn = false;
              this.rountresult[i].Flightdepature = false;
              this.rountresult[i].hoveredName1 = false;
              this.rountresult[i].hoveredName2 = false;
              this.rountresult[i].hoveredName3 = false;
              this.rountresult[i].hoveredName4 = false;
              this.rountresult[i].Refund = refund[i];
              this.rountresult[i].Returnjourney = Arrjourney[i];
              this.rountresult[i].Depaturejourney = depjourney[i];
              this.rountresult[i].TotalAmount = (price[i] + Tax[i]).toFixed(2);
            }

            this.filterData = this.rountresult;

            // console.log(this.rountresult, 'result222222222222222222222222')

            this.rountresult = totalPricefilter.sortBy(this.filterData, [
              "TotalAmount",
            ]);
            //  console.log(this.rountresult, 'resulppppppppppppppppppppppppt')
          });
        }
      }
    },

    gettimedep() {
      for (let i = 0; i < this.rountresult.length; i++) {
        // console.log(this.rountresult, "result222222222222222222222222");
        for (let j = 0; j < this.rountresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Depature.length;

          const time1 = new Date(
            `${this.rountresult[i].Depature[currentIndex].Arrival.Date}T${this.rountresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Depature[nextIndex].Departure.Date}T${this.rountresult[i].Depature[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.rountresult[i].Depature[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },

    gettimeret() {
      for (let i = 0; i < this.rountresult.length; i++) {
        // console.log(this.rountresult, "result222222222222222222222222");
        for (let j = 0; j < this.rountresult[i].Return.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Return.length;

          const time1 = new Date(
            `${this.rountresult[i].Return[currentIndex].Arrival.Date}T${this.rountresult[i].Return[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Return[nextIndex].Departure.Date}T${this.rountresult[i].Return[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.rountresult[i].Return[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },
    priceRange() {
      let min = Infinity;
      let max = -Infinity;

      for (let i = 0; i < this.filterData.length; i++) {
        const totalAmount = parseFloat(this.filterData[i].TotalAmount);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      this.minimum = min.toFixed(2);
      this.maximum = max.toFixed(2);
      this.steps = ((max - min) / 50).toFixed(2);
      this.changevalue[0] = min;
      this.changevalue[1] = max;

      // console.log(this.changevalue, 'abina');
    },

    filterNonStop() {
      this.NonStopFil = !this.NonStopFil;
      this.nonStop = this.filterData;
      this.filNonstop = [];
      // this.rountresult = [];
      if (this.NonStopFil == true) {
        this.rountresult = [];
        this.nonStop.forEach((v) => {
          if (v.Depaturejourney.Stops == 0 && v.Returnjourney.Stops == 0) {
            this.filNonstop.push(v);
            this.rountresult = this.filNonstop;
          }
        });
      } else {
        this.rountresult = this.filterData;
      }
      // console.log(this.rountresult, "fsfsfs.......");
    },

    filterOneStop() {
      this.OneStopFil = !this.OneStopFil;

      // console.log(this.OneStopFil, 'tftftftf.....')
      this.oneStop = this.filterData;
      this.filOneStop = [];
      this.rountresult = [];
      if (this.OneStopFil == true) {
        // alert('Good.yyyyyyyyyyyyyyyyyyyyyyyyyyyyyy....')
        this.oneStop.forEach((v) => {
          if (v.Depaturejourney.Stops == 1 && v.Returnjourney.Stops == 1) {
            this.filOneStop.push(v);
            this.rountresult = this.filOneStop;
            // this.stopMultipleFilter();
          }
        });

        // console.log(this.rountresult, 'filOne1......')
      }

      if (this.OnePlusFil == true && this.OneStopFil == true) {
        // alert('Good.....')
        this.rountresult = [];
        this.filterData.forEach((v) => {
          if (v.Depaturejourney.Stops > 0 && v.Returnjourney.Stops > 0) {
            this.rountresult.push(v);
          }
        });

        // console.log(this.rountresult, 'filOne2.......')
      } else {
        this.rountresult = this.filterData;
      }
      // console.log(this.filOneStop, 'fsfsfs.......')
      // console.log(this.rountresult, 'Normal....')
    },

    filterOnePlusStop() {
      this.OnePlusFil = true;
      this.filOnePlusStop = [];
      this.rountresult = [];
      this.onePlusStop = this.filterData;
      if (this.OnePlusFil == true) {
        this.onePlusStop.forEach((v) => {
          if (v.Depaturejourney.Stops > 1 && v.Returnjourney.Stops > 1) {
            this.filOnePlusStop.push(v);
            this.rountresult = v;
            this.stopMultipleFilter();
          }
        });
      } else {
        this.rountresult = this.filterData;
      }

      // console.log(this.rountresult, "Normal....");
    },

    onewaytrip() {
      let onewayresult = [];
      onewayresult = JSON.parse(localStorage.getItem("onewaytrip"));

      // console.log(onewayresult, 'onewayresult')

      if (onewayresult) {
        for (let i = 0; i < onewayresult.length; i++) {
          // console.log(onewayresult[i], 'onewayresult[i]')
          if (onewayresult[i]) {
            this.Journey = onewayresult[i].DataLists.FlightList.Flight;
            let refund = [];
            let price = [];
            let Tax = [];
            let pricedetail = [];
            let $data = onewayresult[i].OffersGroup.AirlineOffers[0].Offer;

            // console.log($data, 'offer')
            let depsplit = [];
            let resultdata1 = [];
            $data.forEach((s) => {
              // console.log(s, 'pppppppppppppppp')
              price.push(s.TotalPrice.EquivCurrencyPrice);
              Tax.push(s.TaxPrice.EquivCurrencyPrice);
              pricedetail.push(s.OfferItem);
              s.OfferItem.forEach((v) => {
                if (v.Refundable === "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }

                // console.log(v.FareComponent, 'v.FareComponent')
                if (v.FareComponent[0].SegmentRefs) {
                  resultdata1 = v.FareComponent[0].SegmentRefs;
                }
              });

              depsplit.push(resultdata1.split(" "));
            });

            // console.log(depsplit, '$data')
            this.Journey1 =
              onewayresult[i].DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')Seatsseats
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              this.flightsegmet.push(a.SegmentReferences.split(" "));
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);

              // console.log(this.flightsegmetkey, 'vasanthjourney')
            });
            let seats = [];
            seats = onewayresult[i].DataLists.PriceClassList.PriceClass;
            seats.forEach((c) => {
              c.ClassOfService.forEach((v) => {
                for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
                  if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
                    this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
                  }
                }
              });
            });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.onewayresulted.push({ Depature: seg });
                }
              });
            }

            // console.log(this.onewayresulted, 'onewayresultedonewayresultedonewayresulted')

            let depjourney = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0].FareComponent[0];

              let source = [];

              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                }
              }

              depjourney.push(source);
            }

            for (let i = 0; i < this.onewayresulted.length; i++) {
              this.onewayresulted[i].price = price[i];
              this.onewayresulted[i].Taxprice = Tax[i];
              this.onewayresulted[i].pricedetail = pricedetail[i];
              this.onewayresulted[i].Flightreturn = false;
              this.onewayresulted[i].Flightdepature = false;
              this.onewayresulted[i].hoveredName1 = false;
              this.onewayresulted[i].hoveredName2 = false;
              this.onewayresulted[i].hoveredName3 = false;
              this.onewayresulted[i].hoveredName4 = false;
              this.onewayresulted[i].Refund = refund[i];
              this.onewayresulted[i].Depaturejourney = depjourney[i];
              this.onewayresulted[i].TotalAmount = (price[i] + Tax[i]).toFixed(
                2
              );
            }
            this.onewayfilterData = this.onewayresulted;

            // console.log(
            //   this.rountresult,
            //   "result2222222222222222222333333333333"
            // );

            this.onewayresulted = totalPricefilter.sortBy(
              this.onewayfilterData,
              ["TotalAmount"]
            );

            // console.log(this.onewayresulted, 'onewayresulted')
          }
        }
      }
    },

    sended() {
      localStorage.removeItem("onewaytrip");
      localStorage.removeItem("rounttrip");
      this.onewayresulted = [];

      // console.log(this.concatenatedArray, "44444444444444444444444");
      let resul =
        this.Rountdata.request.AirShoppingRQ.DataLists.PassengerList.Passenger;

      // console.log(this.concatenatedArray, "5555555555555555555555");
      if (this.concatenatedArray) {
        this.concatenatedArray.forEach((v) => {
          // console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvv");
          resul.push(v);
        });
      } else {
        // console.log(this.concatenatedArray);
      }
      this.oneway.city = this.red1;
      this.oneway.adult = this.aduls;
      this.oneway.infrant = this.aduls2;
      this.oneway.child = this.aduls1;
      this.oneway.class = this.select;
      let rountdetail = {
        from: null,
        to: null,
        dedate: this.oneway.dedate,
        class: this.oneway.class,
        child: this.oneway.child,
        adult: this.oneway.adult,
        infrant: this.oneway.infrant,
        city: this.oneway.city,
      };
      let result1 = [];
      result1 = JSON.stringify(this.oneway);
      localStorage.setItem("rountData", result1);

      // console.log(this.oneway.from, "ofofofofofofo");

      // console.log(this.oneway.to, "ototototototo");

      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        this.loadSearch = true;
        this.disButton = true;
        let res = "";
        if (this.oneway.from.name) {
          rountdetail.from = this.oneway.from.name;
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.from = this.oneway.from;
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          rountdetail.to = this.oneway.to.name;
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.to = this.oneway.to;
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let result =
          this.Rountdata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;
          result[0].Departure.Date = moment(this.oneway.dedate).format(
            "YYYY-MM-DD"
          );
          result[0].Arrival.AirportCode = this.roundto;
          result[1].Departure.AirportCode = this.roundto;
          result[1].Departure.Date = moment(this.oneway.redate).format(
            "YYYY-MM-DD"
          );
          result[1].Arrival.AirportCode = this.roundfrom;
        }

        // console.log(this.Rountdata, "qwqwqqwqwqwyruyuyu");
        this.$router.push("/loader");

        axios
          .post("https://vtapi.wistirna.com/api/rest", this.Rountdata, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            setTimeout(() => {
              this.responseData.push(response.data.AirShoppingRS);
              let rountData = [];
              rountData = JSON.stringify(this.responseData);
              localStorage.setItem("rounttrip", rountData);

              // console.log(this.responseData, "0077777777777");
              this.$router.push({ path: "/search", query: rountdetail });
            }, 7000);
          });
      } else {
        alert("please enter your detail");
        this.validateOnewayForm();
      }

      // console.log(this.responseData, "0077777777777");
    },

    sendedone() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      let resul =
        this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger;

      // console.log(resul, "5555555555555555555555");
      if (this.concatenatedArray) {
        this.concatenatedArray.forEach((v) => {
          // console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvv");
          resul.push(v);
        });
      } else {
        // console.log(this.concatenatedArray);
      }

      this.oneway.city = this.red1;
      this.oneway.adult = this.aduls;
      this.oneway.infrant = this.aduls2;
      this.oneway.child = this.aduls1;
      this.oneway.class = this.select;

      let rountdetail = {
        from: null,
        to: null,
        dedate: this.oneway.dedate,
        class: this.oneway.class,
        child: this.oneway.child,
        adult: this.oneway.adult,
        infrant: this.oneway.infrant,
        city: this.oneway.city,
      };

      let result1 = [];
      result1 = JSON.stringify(this.oneway);
      localStorage.setItem("rountData", result1);

      // console.log(this.oneway.from, "ofofofofofofo");

      // console.log(this.oneway.to, "ototototototo");

      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.loadSearch = true;
        this.disButton = true;
        let res = "";

        if (this.oneway.from.name) {
          rountdetail.from = this.oneway.from.name;
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.from = this.oneway.from;
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          rountdetail.to = this.oneway.to.name;
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.to = this.oneway.to;
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let result =
          this.onewaydata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;
          result[0].Departure.Date = moment(this.oneway.dedate).format(
            "YYYY-MM-DD"
          );
          result[0].Arrival.AirportCode = this.roundto;
          // result[1].Departure.AirportCode = this.roundto
          // result[1].Departure.Date = moment(this.oneway.redate).format('YYYY-MM-DD')
          // result[1].Arrival.AirportCode = this.roundfrom
        }

        // console.log(this.onewaydata, "qwqwqqwqwqwyruyuyu");
        this.$router.push("/loader");

        axios
          .post("https://vtapi.wistirna.com/api/rest", this.onewaydata, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            setTimeout(() => {
              this.responseData.push(response.data.AirShoppingRS);

              // console.log(this.responseData, " this.responseData");
              let rountData = [];
              rountData = JSON.stringify(this.responseData);

              localStorage.setItem("onewaytrip", rountData);

              // console.log(this.responseData, "0077777777777");
              this.$router.push({ path: "/search", query: rountdetail });
            }, 7000);
          });
      } else {
        alert("please enter your detail");

        this.validateOnewayForm();
      }

      // console.log(this.responseData, "0077777777777");
    },

    sendedMulticity() {
      for (let i = 0; i < this.input.length; i++) {
        if (this.input[i].from) {
          this.input[i].multiFromError = false;

          // console.log(this.input[i].from, "mememememe.....");
        } else {
          this.input[i].multiFromError = true;
        }

        if (this.input[i].to) {
          this.input[i].multiToError = false;
        } else {
          this.input[i].multiToError = true;
        }

        if (this.input[i].departDate) {
          this.input[i].multiDateError = false;
        } else {
          this.input[i].multiDateError = true;
        }
      }
    },
    selected(result) {
      // console.log(result, "itinary seelected");
      this.$router.push("/flightinfo");
      let res = "";
      if (this.oneway.from.name) {
        // rountdetail.from = this.oneway.from.name;
        res = this.oneway.from.name.split(" ");
        this.roundfrom = res[res.length - 1].replace("(", "").replace(")", "");
      } else {
        // rountdetail.from = this.oneway.from;
        res = this.oneway.from.split(" ");
        this.roundfrom = res[res.length - 1].replace("(", "").replace(")", "");
      }

      let res1 = "";
      if (this.oneway.to.name) {
        // rountdetail.to = this.oneway.to.name;
        res1 = this.oneway.to.name.split(" ");
        this.roundto = res1[res1.length - 1].replace("(", "").replace(")", "");
      } else {
        // rountdetail.to = this.oneway.to;
        res1 = this.oneway.to.split(" ");
        this.roundto = res1[res1.length - 1].replace("(", "").replace(")", "");
      }
      // console.log(this.oneway, "this.onewaythis.onewaythis.oneway");

      let $data = localStorage.getItem("rountData");
      let $data1 = JSON.parse($data);
      this.itinaryRequest.SearchClass = $data1.class;
      this.itinaryRequest.OriginCity1 = this.roundfrom;
      this.itinaryRequest.DestinationCity1 = this.roundto;
      this.itinaryRequest.OriginCity2 = this.roundto;
      this.itinaryRequest.DestinationCity2 = this.roundfrom;
      this.itinaryRequest.DisplayedPrice = result.TotalAmount;
      this.itinaryRequest.Adult = $data1.adult;
      this.itinaryRequest.Child = $data1.child;
      this.itinaryRequest.InfantLap = $data1.infrant;

      let depature = result.Depature;

      let indexdata = [];
      depature.forEach((v, i) => {
        this.itinaryRequest[`Carrier${i + 1}`] = v.MarketingCarrier.AirlineID;
        this.itinaryRequest[`Cabin${i + 1}`] = $data1.class;
        this.itinaryRequest[`FlightNumber${i + 1}`] =
          v.MarketingCarrier.FlightNumber;
        this.itinaryRequest[`DepartureDate${i + 1}`] = moment(
          v.Departure.Date
        ).format("YYYY-MM-DD");
        this.itinaryRequest[`DepartureTime${i + 1}`] = moment(
          v.Departure.Time,
          "HH:mm:ss"
        ).format("HH:mm");
        this.itinaryRequest[`ArrivalDate${i + 1}`] = moment(
          v.Arrival.Date
        ).format("YYYY-MM-DD");
        this.itinaryRequest[`ArrivalTime${i + 1}`] = moment(
          v.Arrival.Time,
          "HH:mm:ss"
        ).format("HH:mm");
        this.itinaryRequest[`Origin${i + 1}`] = v.Departure.AirportCode;
        this.itinaryRequest[`Destination${i + 1}`] = v.Arrival.AirportCode;
        this.itinaryRequest[`BookingCode${i + 1}`] = v.Code.ResBookDesigCode;
        if (i <= result.Depature.length - 1) {
          indexdata.push(i + 1);
        }
      });
      this.itinaryRequest.Slice1 = indexdata.toString();

      let retur = result.Return;
      let indexdata1 = [];

      retur.forEach((v, i) => {
        if (i <= result.Return.length - 1) {
          indexdata1.push(i + indexdata.length + 1);
        }
        this.itinaryRequest[`Cabin${i + indexdata.length + 1}`] = $data1.class;
        this.itinaryRequest[`Carrier${i + indexdata.length + 1}`] =
          v.MarketingCarrier.AirlineID;
        this.itinaryRequest[`FlightNumber${i + indexdata.length + 1}`] =
          v.MarketingCarrier.FlightNumber;
        this.itinaryRequest[`DepartureDate${i + indexdata.length + 1}`] =
          moment(v.Departure.Date).format("YYYY-MM-DD");
        this.itinaryRequest[`DepartureTime${i + indexdata.length + 1}`] =
          moment(v.Departure.Time, "HH:mm:ss").format("HH:mm");
        this.itinaryRequest[`ArrivalDate${i + indexdata.length + 1}`] = moment(
          v.Arrival.Date
        ).format("YYYY-MM-DD");
        this.itinaryRequest[`ArrivalTime${i + indexdata.length + 1}`] = moment(
          v.Arrival.Time,
          "HH:mm:ss"
        ).format("HH:mm");
        this.itinaryRequest[`Origin${i + indexdata.length + 1}`] =
          v.Departure.AirportCode;
        this.itinaryRequest[`Destination${i + indexdata.length + 1}`] =
          v.Arrival.AirportCode;
        this.itinaryRequest[`BookingCode${i + indexdata.length + 1}`] =
          v.Code.ResBookDesigCode;
      });

      this.itinaryRequest.Slice2 = indexdata1.toString();

      // console.log(this.itinaryRequest, " this.itinaryRequest");

      let resultData = {
        hit_id: "ca-650932b2905438-25549374-2685",
        post_data: this.itinaryRequest,
        http_data: "-",
        ref_url:
          "http://gfs.metaapi1309/gflights?SearchClass=Economy&OriginCity1=YVR&DestinationCity1=SELOriginCity2=SEL&DestinationCity2=YVR&TripType=RoundTrip&PointOfSaleCountry=CA&DisplayedPrice=5915.24&DisplayedPriceCurrency=CAD&UserLanguage=en&UserCurrency=CAD&Adult=1&Child=0&InfantLap=0&Slice1=1&Slice2=2%2C3&Cabin1=Economy&Carrier1=OZ&DepartureDate1=2023-12-21&DepartureTime1=12%3A05&ArrivalDate1=2023-12-22&ArrivalTime1=17%3A00&Origin1=YVR&Destination1=ICN&BookingCode1=K&FlightNumber1=6101&Cabin2=Economy&Carrier2=OZ&DepartureDate2=2024-01-10&DepartureTime2=20%3A50&ArrivalDate2=2024-01-10&ArrivalTime2=14%3A00&Origin2=ICN&Destination2=SFO&BookingCode2=E&FlightNumber2=212&Cabin3=Economy&Carrier3=AC&DepartureDate3=2024-01-10&DepartureTime3=16%3A35&ArrivalDate3=2024-01-10&ArrivalTime3=19%3A00&Origin3=SFO&Destination3=YVR&BookingCode3=S&FlightNumber3=571&mTime=2023-09-06T12%3A49%3A58.885351Z&ugid=43a49112-d6a5-4b03-970a-f4d126a1deb1&ReferralId=btgfs",
        req_url: "gfs.metaapi1309/flights/itinerary",
        IP_Address: "127.0.0.1",
        meta_portal_id: "btgfs",
      };
      // console.log(resultData)

      axios
        .post("https://vtapi.wistirna.com/api/hittable", resultData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response, "responseresponseresponseresponseresponse");
        });

      localStorage.removeItem("onewayItineyData");
      let selectData = [];
      selectData.push(JSON.stringify(result));
      localStorage.setItem("itineryData", selectData);
    },
    fromClick($event) {
      // console.log($event, "p.....1");
      if ($event.from) {
        $event.from = "";
        this.fromErrorMessage = true;

        // console.log(this.fromErrorMessage, "p.....2");
      }
    },

    toClick($event) {
      // console.log($event, "p.....");
      if ($event.to) {
        $event.to = "";
        this.toErrorMessage = true;

        // console.log(this.toErrorMessage, "p.....3");
      }
    },

    multiFromClick(index) {
      this.input.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true;

            // console.log("vvvvv......4444");
            if (!v.from) {
              v.multiFromError = true;

              // console.log("vvvvv......555");
            } else {
              v.multiFromError = false;

              // console.log("vvvvv......666");
            }
          }
        }
      });
    },

    multiToClick(index) {
      this.input.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true;

            // console.log("vvvvv......111");
            if (!v.to) {
              // console.log("vvvvv......222");
              v.multiToError = true;
            } else {
              v.multiToError = false;

              // console.log("vvvvv......333");
            }
          }
        }
      });
    },

    onChangeFrom() {
      setTimeout(() => {
        if (this.oneway.from) {
          this.fromErrorMessage = false;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.from) {
          this.fromErrorMessage = true;
        }
      }, 100);
    },

    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100);
    },

    onChangeMultiFrom(index) {
      this.input.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
          } else {
            v.multiFromError = true;
          }
        }
      });
    },

    onChangeMultiTo(index) {
      this.input.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
          } else {
            v.multiToError = true;
          }
        }
      });
    },

    validateOnewayForm() {
      if (this.oneway.from) {
        this.fromErrorMessage = false;
      } else {
        this.fromErrorMessage = true;
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (this.oneway.redate) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },

    fetchItems() {
      Object.keys(userData).forEach((icao) => {
        const airport = userData[icao];
        if (airport.iata !== "") {
          this.fromAirports.push({
            iata: airport.iata,
            icao: airport.icao,
            places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
          });
        }
      });
    },

    search(event) {
      this.loading = true;
      const searchString = event.query.toUpperCase();
      setTimeout(() => {
        const iataMatch = [];
        const otherMatches = [];
        this.fromAirports.forEach((e) => {
          const iata = e.iata ? e.iata.toUpperCase() : "";
          const placesLowerCase = e.places.toLowerCase();
          if (iata === searchString) {
            iataMatch.push(e);
          } else if (placesLowerCase.includes(searchString.toLowerCase())) {
            otherMatches.push(e);
          }
        });
        this.airitems = iataMatch.concat(otherMatches);
        this.loading = false;
      }, 500);
    },
    focus1() {
      setTimeout(() => {
        this.$refs.secondAutoComplete.$el.querySelector("input").focus();
      }, 100);
    },

    focus2() {
      this.$refs.dpRef1.openMenu();
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        for (let i = 0; i < 1; i++) {
          // console.log(this.input[0].to, "bfbfbfbf.....");
          this.input[1].from = this.input[0].to;
        }
      }, 10);
    },

    handleResize() {
      if (window.innerWidth < 992) {
        this.isIcon = false;
      } else {
        this.isIcon = true;
      }
    },
  },

  // AutoComplete Functions End

  watch: {
    changevalue(newVal) {
      // alert('Work....')
      this.rountresult = [];

      let sortAmount = [];

      // console.log('Slider value changed:', newVal,);

      for (let i = 0; i < this.filterData.length; i++) {
        if (
          this.filterData[i].TotalAmount >= newVal[0] &&
          this.filterData[i].TotalAmount <= newVal[1]
        ) {
          sortAmount.push(this.filterData[i]);
        }
      }
      this.rountresult = totalPricefilter.sortBy(sortAmount, ["TotalAmount"]);
      // console.log(this.rountresult,'pppppppppppppppppppp')
    },
  },

  computed: {
    filteredCountries() {
      return this.airitems.map((item) => {
        const parts = item.places.split(", ");
        if (parts.length >= 2) {
          const name = `${parts[1]} (${parts[0]})`;
          // const name = `${parts[0]} - ${parts[1]}`;
          const originalName = item.places;
          return {
            name,
            originalName,
          };
        }
      });
    },
  },

  created() {
    setTimeout(() => {
      this.searchresult = true
      this.searchloader = false
    }, 7000)
    this.mydata();
    this.roundData();

    this.red1 = this.$route.query.city;
    this.red = this.$route.query.city;
    console.log(this.red1, "rrrrrrrrrrrrrrr");
    this.actActive();


    this.airlogodata = airLogo;

    this.deatailData();
    let result = "";
    result = localStorage.getItem("rounttrip");
    this.localData.push(JSON.parse(result));
    // console.log(this.localData, "nandu");

    // console.log(this.aduls, "1111");

    // console.log(this.oneway.dedate, "ododod..........");
    this.clicked1 = true;
    // console.log(this.oneway.from, "ofofof.............");
    this.clicked2 = true;
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.fetchItems();

    this.roundedtrip();
    this.onewaytrip();
    this.priceRange();
    this.handleResize();
    this.gettimedep();
    this.gettimeret();
  },
};
</script>

<style scoped>
.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100%;
}

>>>.dp__input_icon {
  cursor: pointer;
  position: absolute;
  top: 64%;
  left: 82%;
  color: black !important;
  transform: translateY(-50%);
  color: var(--dp-icon-color);
}

.mdi-menu-down::before {
  content: "\F035D222";
  display: none !important;
}

.v-input__append>.v-icon {
  position: relative;
  right: 43px;
  top: -4px;
}

.dp__input_wrap {
  position: relative;
  width: 100%;
  box-sizing: unset;
  top: -10px;
}

.labeling {
  position: relative;
  bottom: 9px;
}

.v-input--density-default {
  --v-input-control-height: 38px;
  position: relative;
  top: -22px;
}

.v-slider.v-input--horizontal {
  position: relative;
  top: 0px;
}

.cart-adult {
  padding: 15px;
  width: 500px;
  cursor: pointer;
  position: relative;
  top: -65px;
}

>>>.dp__clear_icon {
  display: black;
  position: absolute;
  top: 50%;
  right: -7%;
  /* right: 0; */
  transform: translateY(-50%);
  cursor: pointer;
}

.adult {
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #05386d;
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

.radio {
  background-color: #e2e7ff;
  border-radius: 14px;
  color: #007bff !important;
  width: 120px;
  font-size: 15px !important;
  font-weight: 400px;
}

.normal-radio {
  font-size: 14px !important;
  font-weight: 400px;
  height: 30px;
  width: 130px;
}

.air-title {
  background-color: #5191FA;
  color: white;
  padding: 5px;
}

.cart1 {
  box-shadow: none;
  border: 1px solid lightgray;
  font-size: 15px;
  font-weight: 500;
  max-width: 208px;
  border-radius: 5px;
}

.cart1 p {
  text-align: center;
}

hr {
  margin: 0rem 0 !important;
}

.rec-num {
  font-size: 14px;
  font-weight: 600;
  color: navy;
}

.bord {
  border-right: 2px solid gray;
  position: relative;
  top: -2px;
  height: 63px;
}

.depar {
  background-color: lightgray;
  padding: 3px;
}

.res-name {
  font-size: 13px;
  font-weight: 500;
  margin-left: 8px;
  margin-bottom: 0;
}

.res-stop {
  font-size: 12px;
  font-weight: 600;
  color: grey;
}

/* .res-stop {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px !important;
  position: relative;
  top: 2px;
  right: 12px;
  color: grey;
} */

.res-time {
  font-size: 12px;
  font-weight: 500;
}

.dot-1 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 42%;
  left: 30%;
  z-index: 1;
}

.dot-2 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 18%;
  left: 50%;
  z-index: 1;
}

.res-icon {
  border: 1px solid #05386d;
  position: relative;
  top: 14px;
  width: 92%;
}

.res-icon1 {
  float: right;
  color: #05386d;
  font-size: 18px;
}

>>>.v-input__details {
  display: none;
}

.v-input--density-default {
  --v-input-control-height: 38px;
}

.stop {
  font-size: 16px;
  font-weight: 500;
  padding: 7px;
}

.v-slider.v-input--horizontal .v-slider-thumb {
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: 3px solid #3c3cde;
  border-radius: 50px;
}

.v-slider.v-input--horizontal .v-slider-track__fill {
  height: 3px;
  background-color: #7474e0;
}

/*  */
.v-slider-thumb__surface {
  height: 12px !important;
  width: 12px !important;
}

.v-checkbox .v-selection-control {
  min-height: var(--v-input-control-height);
  position: relative;
  top: 12px;
}

>>>.v-timeline-divider__dot--size-large {
  height: 22px;
  width: 56px;
}

>>>.v-timeline-divider__inner-dot {
  border-radius: 15px;
}

>>>.v-timeline-divider__inner-dot span {
  font-size: 11px;
  font-weight: 600;
}

.mdi-airplane-takeoff {
  content: "\F05D5";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

.mdi-airplane-landing {
  content: "\F05D4";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

>>>.dp__input_icon {
  display: none;
  top: 64%;
  left: -5% !important;
}

>>>.p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>>.p-icon {
  display: none;
}

.p-error {
  font-size: 12px;
  color: red;
}

.dropdown-box {
  padding: 8px 10px;
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  /* background-color: lightgray;
  color: black; */
}

>>>.p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

.p-float-label {
  width: 100%;
}

.p-float-label label {
  position: absolute;
  pointer-events: none;
  top: 25%;
  left: 0px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  color: gray;
}

.p-component * {
  box-sizing: border-box;
  /* padding: 2px;
  padding-top: 4px;
  padding-bottom: 4px; */
}

>>>.p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}

.dp__icon {
  display: block;
}

>>>.v-icon {
  --v-icon-size-multiplier: 1;
  align-items: center;
  justify-content: flex-start;
  overflow:unset !important;
}

>>>.dp__input {
  border: none;
  border-bottom: 1px solid lightgrey !important;
  border-radius: 0px !important;
  padding-left: 2px;
  padding-right: 2px;
  height: 24px;
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  color: grey;
}

.calendar-container {
  display: flex;
  position: relative;
}

.label-top {
  position: absolute;
  top: 0 !important;
  left: -8px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label {
  position: absolute;
  top: 28px !important;
  left: -8px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 16px;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.label-top1 {
  position: absolute;
  top: 0 !important;
  left: -8px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  top: 28px !important;
  left: -8px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.multiLabel-top {
  position: absolute;
  left: -8px !important;
  bottom: 0px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute !important;
  top: 28px !important;
  left: -9px !important;
  transform: translateY(-100%);
  background-color: transparent !important;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.delete-icon {
  display: flex;
  justify-content: end;
}

.hoverData1 {
  position: absolute;
  left: 50%;
  top: -27px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverDataStop1 {
  position: absolute;
  left: 40.4%;
  top: -27px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverDataStop2 {
  position: absolute;
  left: 61%;
  top: -27px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* cursor: pointer; */
}

/* .content:hover{
  width:100%;
  font-size:13px;
} */
.hoverData2 {
  position: absolute;
  left: 63%;
  bottom: 63px;
  border-radius: 5px;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverData1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverDataStop1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverDataStop2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverData2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.refunt {
  color: blue;
}

>>>.v-timeline--vertical.v-timeline {
  grid-row-gap: 1px;
}

>>>.v-timeline--vertical.v-timeline {
  /* grid-row-gap: 24px; */
  height: auto !important;
}

.filter-toggle {
  display: none;
}

.hoveredCard:hover {
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px black;
}


.time-section .v-icon {
  font-size: 18px !important;
  color: grey;
}

.time-section span {
  font-size: 12px;
  margin-left: 5px;
  font-weight: 500;
  color: grey;
}

@media only screen and (max-width: 991px) {
  .filter-toggle {
    display: block;
  }
}


@media only screen and (max-width: 768px) {
  .filter-toggle {
    display: block;
  }

  .airline-logo {
    width: auto;
  }

  .airline-logo .v-img {
    width: 65%;
  }

  .res-name {
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }

  .res-time {
    font-size: 10px;
  }



  /* .res-stop {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px !important;
    position: relative;
    top: 2px;
    right: 10px;
    color: grey;
  } */


  .time-section .v-icon {
    font-size: 15px !important;
    color: grey;
  }

  .time-section span {
    font-size: 11px !important;
    font-weight: 500 !important;
    color: grey;
  }
}



@media only screen and (max-width: 480px) {
  .hoverDataStop1 {
    left: 38%;
  }

  .hoverDataStop2 {
    left: 59%;
  }
}
</style>
