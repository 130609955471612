<template>
    <div class="row d-flex flex-column align-end">
        <div class="col-lg-4 d-flex flex-column align-center" style="position: absolute; z-index: 2;">
            <v-card v-if="loginbox" style=" width:80%; border-radius:7px; " outlined   v-click-outside="onClickOutside">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-md-12 main-page">
                        <div class="bg-light main-page mx-auto shadow-none"
                            style="background-color:#f8b2b2; width: 100%; height: auto;">
                            <div class="col-12 pe-3 pt-2" align="end" @click="closeLoginBox()">
                                <v-icon style="color:navy" size="small">mdi-close-circle</v-icon>
                            </div>

                            <v-form ref="form" v-model="valid" @submit.prevent="submitForm" style="width: 90%;">

                                <v-text-field v-model="loginuser.userEmail" :rules="eMail" label="Email" 
                                    variant="underlined" placeholder="Enter your Email"
                                    style="height:55px;font-size: 15px !important"></v-text-field>
                                <!-- <span style="color: red;">invalid Username</span><br> -->

                                <v-text-field v-model="loginuser.userPass" :rules="uPass" label="Password"
                                    placeholder="Enter your password" variant="underlined" required
                                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                                    style="height:55px;font-size: 15px !important"></v-text-field>
                                <!-- <span style="color: red;">invalid password</span> -->

                                <div class="row">
                                    <div class="col-6">
                                        <v-checkbox v-model="terms" label="Remember Me"></v-checkbox>
                                    </div>
                                    <div class="col-6">
                                        <span style="position: relative;
    top: 15px;">
                                            <router-link to="/register" style="font-weight:500;
                                        font-size:13px;letter-spacing: 1px;">
                                            Forgot  Password</router-link></span>
                                    </div>
                                </div>

                                

                                <div class="d-flex mb-3">
                                    <div class="col-9" align="end">
                                    <v-btn style="background-color:#050953; color:white" width="60%" :disabled="btnDisabled" type="submit">Login</v-btn>
                                    </div>
                                    <div class="col-3 ps-1" align="center">
                                        <v-progress-circular v-if="loadLogin" :width="3" style="color:navy"
                                            indeterminate></v-progress-circular>
                                    </div>
                                </div>
                                <!-- <v-divider></v-divider> -->

                                <p class="text-center" style="font-weight: 500;font-size: 15px;">Create an Account

                                <span @click="registerCom = true, loginbox = false" style="cursor: pointer;"> 
                                        <a href="#">Sign Up</a>
                                       </span>
                           
                                </p>
                            </v-form>

                        </div>
                    </div>
                </div>
            </v-card>
        </div>
    </div>
    <div v-if="registerCom">
        <registerCom />
    </div>
</template>


<script>
import registerCom from '@/components/registerCom.vue';
import axios from 'axios'

export default {
    components: {
        registerCom
    },
    data() {
        return {
            loginbox: true,
            registerCom: false,
            terms: false,
            visible: false,
            loadLogin:false,
            btnDisabled:false,



            loginuser: {
                userEmail: "",
                userPass: ""
            },

            eMail: [
                v => !!v || 'Please enter your Username',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            uPass: [
                v => !!v || 'Please enter your Password'
            ],

            loginReq: {
                "request_type": "user_login",
                "email": "",
                "password": "",
                "token": "Tc7RQaX79Hrx5CFK"
            }
        }
    },
    methods: {
        onClickOutside(){
this.loginbox=false
        },

        submitForm() {
            if (this.valid) {
                console.log(this.loginuser)

                this.loadLogin=true
                this.btnDisabled=true

                this.loginReq.email = this.loginuser.userEmail
                this.loginReq.password = this.loginuser.userPass
                console.log(this.loginReq, 'kkkk.....')

                axios.post('https://vtapi.wistirna.com/api/rest', this.loginReq, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log('Response:', response.data);
                        if (response.data == true) {
                            // alert("Login Successfully...")
                            this.loginbox = false

                            localStorage.setItem('value', true)
                            // JSON.stringify(localStorage.setItem('loginDatas', this.loginuser))
                            let logindata = JSON.stringify(this.loginuser)
                            localStorage.setItem("loginDatas", logindata)

                            setInterval(() => {
                                location.reload();
                            }, 500);
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error.response ? error.response.data : error.message);
                        alert("wrong details...")
                    });
            }

        },

        closeLoginBox() {
            this.loginbox = false;
            this.$emit('close'); // Emit the 'close' event to the parent component
            console.log(this.loginbox, "1111111")
        },
    },
}
</script>



<style scoped>
/* Login Page */

.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .form-btn {
  padding-left: 40px;
  color: white;
} */

.btn-box {
    background-color: transparent;
    margin: 5px;
    padding: 2px;
    box-shadow: none;
}

.btn-active {
    background-color: blueviolet;
    color: white;
}

.v-checkbox .v-input__details {
    display: none;
}
>>>.v-label--clickable {
    cursor: pointer;
    font-size: 13px;
}
>>>.v-input__details {
    display: block !important;
}

/* Login Page End */



@media only screen and (max-width: 992px) {

    .form-btn {
        padding: 10px 0px 0px 0px !important;
        /* color: white; */
    }

    .btn-box,
    .pipe {
        color: blueviolet !important;
    }

    .btn-active {
        background-color: blueviolet;
        color: white !important;
    }
}
</style>