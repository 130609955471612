<template>
  <nav class="navbar navbar-expand-md navbar-light  bg-back fixed-top px-3" :class="{'bg-back-reduced': scrolled }" 
  :style="{ height: navbarHeight, backgroundColor: backColor }">
    <v-img :src="require('@/assets/skyroute75-4.svg')" height="55px" style="cursor: pointer; margin-right: 25%;"
     @click="$router.push('/')" />
    <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
</button> -->
    <div class="dropdown mt-2 navbar-toggler" id="dropdownExample">
      <button class="btn dropdown-toggle " type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" href="#">

          <div>
            <v-btn @click="login" depressed color="#050953" height="20px" rounded="1" style="font-size:12px !important">
              Login | Sign up
            </v-btn>

          </div>

        </a>


      </div>
    </div>


    <!-- 
                <div class="dropdown mt-2" id="dropdownExample">
    <button class="btn btn-secondary dropdown-toggle  navbar-toggler" type="button" id="dropdownMenuButton"
        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      
    </button>
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" href="#">Action</a>
        <a class="dropdown-item" href="#">Another action</a>
        <a class="dropdown-item" href="#">Something else here</a>
    </div>
</div> -->









    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active mx-4" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-4" href="#">About us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-4" href="#">Destination</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-4" href="#">Contacts</a>
          </li>
        </ul> -->

      <v-spacer></v-spacer>


      <!-- <v-btn class="text-none me-4  fw-bolder fs-6" color="primary" style="color: white !important;"
         rounded="5" variant="elevated" height="40px">
          View Booking
        </v-btn> -->



      <div v-if="userDetails">
        <div style="margin-right: 40px">
          <v-btn @click="login" depressed color="#050953" rounded="2" style="font-size: 15px" :style="{ height: btnHeight}">
            Login | Sign up
          </v-btn>
          <!-- <span class="pipe text-light">|</span> -->
          <!-- <v-btn @click="register" height="30px" class="btn-box text-light" style="color: #FF5400 !important;">
              Register
            </v-btn> -->
        </div>
      </div>



      <div v-if="userProfile" style="width:18%">
        <v-menu style="cursor:pointer" min-width="200px" rounded>
          <template v-slot:activator="{ props }">
            <div class="d-flex align-center">
              <v-btn icon v-bind="props">
                <v-avatar color="light" size="large">
                  <v-icon icon="mdi-account-circle"></v-icon>
                </v-avatar>
              </v-btn>

              <h5 class="text-light px-2 m-0">My Profile</h5>
            </div>
          </template>

          <v-card>
            <v-card-text>
              <div class="mx-auto text-center">
                <v-avatar color="brown">
                  <v-icon icon="mdi-account-circle"></v-icon>
                  <!-- <span class="text-h5">{{ user.initials }}</span> -->
                </v-avatar>
                <h6>{{ user.email }}</h6>
                <!-- <p class="text-caption mt-1">
                      {{ user.email }}
                    </p> -->
                <v-divider class="my-3"></v-divider>
                <v-btn rounded variant="text">
                  Edit Account
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn rounded variant="text" @click="deleteItem()">
                  Log Out
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>

    </div>

  </nav>

  <div v-if="loginform">
    <loginCom @close="closeLogin()"></loginCom>
  </div>
  <div v-if="registerform">
    <registerCom @close="closeRegister()"></registerCom>
  </div>
</template>

<script>
// import loginCom from '@/components/loginCom.vue'
import loginCom from '@/components/loginCom.vue'
import registerCom from '@/components/registerCom.vue';

export default {

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  components: {
    registerCom,
    loginCom
  },
  data() {
    return {
      loginform: false,
      registerform: false,

      userDetails: true,
      userProfile: false,


      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      navbarHeight: '65px',
      btnHeight:'35px',
      backColor:'white',

      user: {
        initials: '',
        // fullName: 'John Doe',
        email: '',
      },

    }
  },
  methods: {
    login() {
      this.loginform = !this.loginform;
      this.registerform = false
    },
    closeLogin() {
      this.loginform = false;
    },
    register() {
      this.registerform = !this.registerform; // Toggle the value of registerform
      this.loginform = false; // Close the login form if open
    },
    closeRegister() {
      this.registerform = false;
    },

    getUser() {
      let userData = JSON.parse(localStorage.getItem('value'))
      console.log(userData, 'user....')
      if (userData) {
        this.userDetails = false
        this.userProfile = true
      }
      else {
        this.userDetails = true
        this.userProfile = false
      }
    },

    deleteItem() {
      localStorage.removeItem("value");
      this.userDetails = true
      this.userProfile = false
    },

    getLoginDatas() {
      let userItems = JSON.parse(localStorage.getItem('loginDatas'))
      console.log(userItems, 'loginddddd......')
      // this.user.email = userItems.userEmail
      // this.user.email = userItems.userPass
    },

    handleScroll() {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true;
        this.navbarHeight = '45px'; 
        this.btnHeight = '30px';
        this.backColor='white';
      } 
      else {
        this.navbarHeight = '65px'; 
        this.btnHeight = '35px';
        this.backColor='white';
      }

      this.lastPosition = window.scrollY;
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },

  mounted() {
    this.getUser()
    this.getLoginDatas()
  },


}
</script>


<style>
.bg-back {
  --bs-bg-opacity: 1;
  background-color: #ffffffab;
}

/* navbar */
.logo {
  width: 180px;
}

.nav-link {
  font-weight: var(--font-600);
  font-size: var(--font-18);
}



.btn-white {
  background-color: white;
  padding: 10px 25px;
  border-radius: 15px;
}

.btn-text {
  color: var(--primary-color);
  font-weight: var(--font-700);
}

.v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
}

.v-btn__content {
  /* color: #FF5400; */
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
}

.book-ticket {
  /* color: #FF5400; */
  background-color: #f0f0f0;
}

/* navbar end */



/* Login Page */
.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
  /* color: white; */
}

.btn-active {
  background-color: blueviolet;
  /* color: white; */
}

.v-checkbox .v-input__details {
  display: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.navbar-toggler {
  border: none !important
}

.dropdown-toggle {
  white-space: nowrap;
  border: 1px solid !important;
}


/* .v-app-bar {
  height: 100px;
  background-image: url('../assets/flight.jpg');
  background-size: 100% 100%;
} */

/* .btn-box,
.pipe {
  color: white !important;
} */


.navbar {
  transition: height 0.2s;
}

</style>