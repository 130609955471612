import { createRouter, createWebHistory } from 'vue-router';
// import parentCompo from '../components/parentCompo.vue'
// import homePage from '../components/homePage.vue'
import date from '../components/dateCompo.vue'
import flightInfo from '../components/flightInfo.vue'
import confirmBook from '../components/confirmBook.vue'
import TelInput from '../components/telInput.vue';
import bookingCofirm from '../components/bookingCofirm.vue'
import hello from '../components/HelloWorld.vue'
import search from '../components/SearchResult.vue'
import loader from '../components/loaderComponent.vue'
import travellerDetail from '@/components/travellerDetails.vue';

const routes = [
  {
    path:"/",
    component:hello
  },

  {
    path:"/search",
    component:search
  },
  {
    path:"/loader",
    component:loader
  },
  {
    path:"/travellerDetail",
    component:travellerDetail
  },
  {
    path:"/flightInfo",
    component:flightInfo
  },
  {
    path:"/confirmBook",
    component:confirmBook
  },
  {
    path:"/date",
    component:date
  },
  {
    path:"/telInput",
    component:TelInput
  },
  {
    path:"/bookingCofirm",
    component:bookingCofirm
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

