<template>
    <vue-tel-input v-model="phone"></vue-tel-input>
   fwrtrttre {{phone  }}
  </template>
<script>
import { VueTelInput } from 'vue-tel-input'
export default{
    components: {
    VueTelInput,
  },
    data(){
        return{
            phone:""
        }
    }
}


</script>